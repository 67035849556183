import { Subscriber } from '../Subscriber';
import { Notification } from '../Notification';
export function observeOn(scheduler, delay = 0) {
  return function observeOnOperatorFunction(source) {
    return source.lift(new ObserveOnOperator(scheduler, delay));
  };
}
export class ObserveOnOperator {
  constructor(scheduler, delay = 0) {
    this.scheduler = scheduler;
    this.delay = delay;
  }
  call(subscriber, source) {
    return source.subscribe(new ObserveOnSubscriber(subscriber, this.scheduler, this.delay));
  }
}
export class ObserveOnSubscriber extends Subscriber {
  constructor(destination, scheduler, delay = 0) {
    super(destination);
    this.scheduler = scheduler;
    this.delay = delay;
  }
  static dispatch(arg) {
    const {
      notification,
      destination
    } = arg;
    notification.observe(destination);
    this.unsubscribe();
  }
  scheduleMessage(notification) {
    const destination = this.destination;
    destination.add(this.scheduler.schedule(ObserveOnSubscriber.dispatch, this.delay, new ObserveOnMessage(notification, this.destination)));
  }
  _next(value) {
    this.scheduleMessage(Notification.createNext(value));
  }
  _error(err) {
    this.scheduleMessage(Notification.createError(err));
    this.unsubscribe();
  }
  _complete() {
    this.scheduleMessage(Notification.createComplete());
    this.unsubscribe();
  }
}
export class ObserveOnMessage {
  constructor(notification, destination) {
    this.notification = notification;
    this.destination = destination;
  }
}
