<ng-container *ngIf="!multiple_upload" class="cameraIcon">
  <div class="avatar-img">
    <img
      *ngIf="avatarImg"
      [src]="avatarImg"
      (click)="showImageDialog(avatarImg)"
    />
    <div class="row screen-lg" style="margin-bottom: 5px; margin-right: 36px">
      <div class="col-md-6 col-sm-6">
        <div (click)="avater.click()">
          <span class="material-icons uploadIcon"> perm_media </span>
          <span class="fileText">{{
            "COMMON.PLEASE_SELECT_FILE" | translate
          }}</span>
          <input
            type="file"
            (change)="onSelectFileChange($event)"
            #avater
            accept=".pdf,.doc,.docx"
            [multiple]="multiple_upload"
            hidden
          />
        </div>
      </div>
      <!-- <div class="col-md-6 col-sm-6 display-camera-icon">
        <span class="material-icons cameraSpan" (click)="openCameraDialog()"
          >add_a_photo</span
        >
        <span class="fileText" style="margin-top: 3px">{{
          "COMMON.CAMERA" | translate
        }}</span>
      </div> -->
    </div>
  </div>
</ng-container>
