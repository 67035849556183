import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  AfterViewInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { filter, map, startWith, take, takeUntil, delay } from 'rxjs/operators';
import {MatSelect} from '@angular/material/select';

interface Branch {
  id: string;
  branchName: string;
  branchCode: string;
}

@Component({
  selector: 'search-autocomplete-branch',
  templateUrl: './search-autocomplete-branch.component.html',
  styleUrls: ['./search-autocomplete-branch.component.scss'],
})
export class SearchAutoCompleteBranch
  implements OnInit, AfterViewInit, OnDestroy
{
  public branchFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public branches: ReplaySubject<Branch[]> = new ReplaySubject<Branch[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  @Input() set data(data: any[]) {
    this._data = data;
    this.branches.next(this.data.slice());
  }

  get data(): any[] {
    return this._data;
  }

  private _data: any[];

  @Output() result: EventEmitter<any> = new EventEmitter<any>();
  @Input() outlineLabel: String;
  @Input() itemMultiCtrl: UntypedFormControl;
  @Input() isSpecialBranch: boolean;

  account: any = {};

  constructor() {}

  ngOnInit() {
    this.branchFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy), delay(1000))
      .subscribe(() => {
        this.filterBranches();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onChange($event) {
    this.result.emit($event);
  }

  protected setInitialValue() {
    this.branches.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      this.singleSelect.compareWith = (a: Branch, b: Branch) =>
        a && b && a.id === b.id;
    });
  }

  protected filterBranches() {
    if (!this.data) {
      return;
    }

    let search = this.branchFilterCtrl.value;
    if (!search) {
      this.branches.next(this.data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.branches.next(
      this.data.filter(
        (branch) => branch.branchName.toLowerCase().indexOf(search) > -1
      )
    );
  }
}
