const ObjectUnsubscribedErrorImpl = (() => {
  function ObjectUnsubscribedErrorImpl() {
    Error.call(this);
    this.message = 'object unsubscribed';
    this.name = 'ObjectUnsubscribedError';
    return this;
  }
  ObjectUnsubscribedErrorImpl.prototype = Object.create(Error.prototype);
  return ObjectUnsubscribedErrorImpl;
})();
export const ObjectUnsubscribedError = ObjectUnsubscribedErrorImpl;
