import { Observable } from '../Observable';
import { asap } from '../scheduler/asap';
import { isNumeric } from '../util/isNumeric';
export class SubscribeOnObservable extends Observable {
  constructor(source, delayTime = 0, scheduler = asap) {
    super();
    this.source = source;
    this.delayTime = delayTime;
    this.scheduler = scheduler;
    if (!isNumeric(delayTime) || delayTime < 0) {
      this.delayTime = 0;
    }
    if (!scheduler || typeof scheduler.schedule !== 'function') {
      this.scheduler = asap;
    }
  }
  static create(source, delay = 0, scheduler = asap) {
    return new SubscribeOnObservable(source, delay, scheduler);
  }
  static dispatch(arg) {
    const {
      source,
      subscriber
    } = arg;
    return this.add(source.subscribe(subscriber));
  }
  _subscribe(subscriber) {
    const delay = this.delayTime;
    const source = this.source;
    const scheduler = this.scheduler;
    return scheduler.schedule(SubscribeOnObservable.dispatch, delay, {
      source,
      subscriber
    });
  }
}
