<div style="border:5px solid darkolivegreen">
    <webcam [height]="400" [width]="248"
            [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)"
            [allowCameraSwitch]="allowCameraSwitch"
            [switchCamera]="nextWebcamObservable"
            [videoOptions]="videoOptions"
            [imageQuality]="1"
            (cameraSwitched)="cameraWasSwitched($event)"
            (initError)="handleInitError($event)">
    </webcam>
  </div>
  <div class="center">
    <button mat-raised-button style="margin-top: 5px;" color="primary" (click)="triggerSnapshot();">
      <span class="fileText">{{"COMMON.TAKE_A_PHOTO" | translate}}</span>
    </button>
  </div>
