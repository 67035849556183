import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import printJS from 'print-js';
import {TranslateService} from '@ngx-translate/core';
import {BaseService} from '../../service/base.service';
import {OrdersCreationService} from '../../../pages/point-of-sale/orders-creation/orders-creation.service';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  remainBalanceControl = new UntypedFormControl(0);
  direction: string;
  header = null;
  body = null;
  print = null;
  hasInvoice = null;
  ordersCount = null;
  countOfOrders: number;
  yes = null;
  no = null;

  sessionData = JSON.parse(localStorage.getItem('SessionData'));

  constructor(public _translate: TranslateService,
              private _base: BaseService,
              @Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              private _service: OrdersCreationService,
  ) {
    if (this?.data?.ordersCount){
      this.openedOrdersCount(this?.sessionData?.id)
    }

  }

  ngOnInit(): void {
    const lang = localStorage.getItem('LANG');
    this.direction = lang === 'ar' ? 'rtl' : 'ltr';

    if (this.data) {

      if (this.data.yes) {
        this.yes = this.data.yes;
      }

      if (this.data.no) {
        this.no = this.data.no;
      }

      if (this.data.header) {
        this.header = this.data.header;
      }

      if (this.data.body) {
        this.body = this.data.body;
      }
      if (this?.data?.print) {
        this.print = this.data.print;
      }
      if (this?.data?.ordersCount) {
        this.ordersCount = this.data.ordersCount;
      }
      if (this?.data?.hasInvoice){
        this.hasInvoice = this.data.hasInvoice;
      }
    }
  }


  printReport() {
    console.log('this.sessionData.id',this.sessionData.id);
    let lang = this._translate.currentLang;
    let filename = 'pos_session_' + lang;
    let reportname = 'pos_session_' + lang;

    let reportObj = {
      dateWrapper: {
        fromDate: '',
        toDate: ''
      },
      fileName: filename,
      paramters: {
        posSessionId: `${this.sessionData.id}`,
      },
      reportName: reportname
    };
    this.printPdf(reportObj, filename);
  }
  printPdf(reportObj, filename) {
    this._base.downLoad(reportObj, `api/v1/report/pdf`)
      .subscribe(res => {
        const blob = new Blob([res], {type: 'application/pdf'});
        const url = URL.createObjectURL(new File([blob], filename));
        printJS({
          printable: url,
          type: 'pdf',
          showModal: true,
          onIncompatibleBrowser: () => {
            console.log('Browser Not Compitable');
            window.alert('Browser Not Compitable');
          }
        });
      });
  }
  openedOrdersCount(id){
    this._service.openedOrdersCount(id).subscribe(res => {
      if (res.success && res.data) {
        this.countOfOrders = res.data;
      }
    });
  }
  save(confirm,print){
    this.dialogRef.close({
      confirm:confirm,
      safeRemainBalance:this.remainBalanceControl.value,
      print:print
    });
  }

  hasNoInv(){
    this.dialogRef.close({
      hasInvoice:false,
    });
  }
}
