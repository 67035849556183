import { Observable } from '../Observable';
export function throwError(error, scheduler) {
  if (!scheduler) {
    return new Observable(subscriber => subscriber.error(error));
  } else {
    return new Observable(subscriber => scheduler.schedule(dispatch, 0, {
      error,
      subscriber
    }));
  }
}
function dispatch({
  error,
  subscriber
}) {
  subscriber.error(error);
}
