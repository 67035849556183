const ArgumentOutOfRangeErrorImpl = (() => {
  function ArgumentOutOfRangeErrorImpl() {
    Error.call(this);
    this.message = 'argument out of range';
    this.name = 'ArgumentOutOfRangeError';
    return this;
  }
  ArgumentOutOfRangeErrorImpl.prototype = Object.create(Error.prototype);
  return ArgumentOutOfRangeErrorImpl;
})();
export const ArgumentOutOfRangeError = ArgumentOutOfRangeErrorImpl;
