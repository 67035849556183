<div class="language-button">
  <button mat-icon-button [matMenuTriggerFor]="languageMenu">
    <span style="font-weight: bold;font-size: 15px" *ngIf="_translate.currentLang === 'ar'"
      >AR</span
    >
    <span style="font-weight: bold;font-size: 15px" *ngIf="_translate.currentLang === 'en'"
      >EN</span
    >
  </button>
  <mat-menu #languageMenu="matMenu">
    <button
      *ngIf="_translate.currentLang !== 'ar'"
      mat-menu-item
      (click)="changeLanguage('ar')"
    >
      <span style="margin-left: 28px; font-weight: bold">AR</span>
    </button>
    <button
      *ngIf="_translate.currentLang !== 'en'"
      mat-menu-item
      (click)="changeLanguage('en')"
    >
      <span style="margin-right: 28px; font-weight: bold">EN</span>
    </button>
  </mat-menu>
</div>
