import {Router} from '@angular/router';
import {MessagingService} from './../../../service/messaging.service';
import {ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import moment from 'moment';
import {BaseService} from '../../../service/base.service';
import {Constant} from '../../../constant/constant';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {Subject} from 'rxjs';
import {MatButton} from '@angular/material/button';
import {TemplatePortal} from '@angular/cdk/portal';
import {TransferOrderService} from "../../../../pages/inventory-transfer/orders/service/transfer-order.service";

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit , OnDestroy {
  @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;
  unreadCount: number = 0;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  notificationArr = [];
  bellIconPath: String;
  unReadedNotificationCount: number = 0;
  production = this._base.production;
  billIconColor = this._base.billIconColor;
  constructor(private messagingService: MessagingService,
              private _router: Router,
              private _base: BaseService,
              public constant: Constant,
              private _changeDetectorRef: ChangeDetectorRef,
              private _overlay: Overlay,
              private _viewContainerRef: ViewContainerRef,
              private transferOrderService: TransferOrderService
  ) {
    this.transferOrderService.refreshNeeded$.subscribe((res) => {
        this.getUserNotifications();
    })

    this.bellIconPath = this.production ?  'assets/images/bell-ring.png' : 'assets/images/bell_dev.png'
  }

  getUserNotifications() {
    this.messagingService.getUserNotifications().subscribe(res => {
      if (res.data) {
        let notificationDate = res.data.content.map(notification => {
          notification.notificationDate = notification.notificationDate ? moment(new Date(notification.notificationDate)).calendar() : moment().calendar();
          return notification;
        });
        this.notificationArr = notificationDate;
        this.unReadedNotificationCount = this.notificationArr.filter(notification => notification.isReaded == false).length;
      }
    });
  }

  ngOnInit(): void {
    this.messagingService.requestPermission();
    this.getUserNotifications();
    //-----------------------------------------------------
    this.messagingService.receiveMessage().subscribe(
      (payload) => {
        console.log('new message received. ', payload);
        let notificationObj = {};
        notificationObj['title'] = payload.notification.title;
        notificationObj['message'] = payload.notification.body;
        notificationObj['targetId'] = payload.data.targetId;
        notificationObj['targetId2'] = payload.data.targetId2;
        notificationObj['notificationType'] = JSON.parse(payload.data.notificationType);
        notificationObj['isApproved'] = payload.data.isApproved;
        notificationObj['notificationDate'] = moment().calendar();
        notificationObj['isReaded'] = false;
        console.log('notificationObj -- ', notificationObj);
        this.notificationArr.unshift(notificationObj);
        this.unReadedNotificationCount++;
      },
      (err) => {
        console.log('err', err);
      });
  }

  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if ( this._overlayRef )
    {
      this._overlayRef.dispose();
    }
  }

  // toggleNotification() {
  //   document.getElementById('notificationContentId').classList.toggle('showNotificationContent');
  // }

  /**
   * Open the notifications panel
   */
  openPanel(): void
  {
    // Return if the notifications panel or its origin is not defined
    if ( !this._notificationsPanel || !this._notificationsOrigin )
    {
      return;
    }

    // Create the overlay if it doesn't exist
    if ( !this._overlayRef )
    {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
  }

  /**
   * Close the notifications panel
   */
  closePanel(): void
  {
    this._overlayRef.detach();
  }

  private _createOverlay(): void
  {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop     : true,
      backdropClass   : 'fuse-backdrop-on-mobile',
      scrollStrategy  : this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay.position()
        .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX : 'start',
            originY : 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX : 'start',
            originY : 'top',
            overlayX: 'start',
            overlayY: 'bottom'
          },
          {
            originX : 'end',
            originY : 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          },
          {
            originX : 'end',
            originY : 'top',
            overlayX: 'end',
            overlayY: 'bottom'
          }
        ])
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  private _calculateUnreadCount(): void
  {
    let count = 0;

    if ( this.notificationArr && this.notificationArr.length )
    {
      count = this.notificationArr.filter(notification => !notification.read).length;
    }

    this.unreadCount = count;
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(): void
  {
    // Mark all as read
    this.messagingService.readAllNotifications().subscribe();
  }

  /**
   * Toggle read status of the given notification
   */
  toggleRead(notification : any): void
  {
    if (notification && !notification.isReaded) {
      this.messagingService.updateNotificationIsRead(notification.targetId, true,notification.id).subscribe(res => {
        console.log('Readed -- ', res);
        let selectedNotification = this.notificationArr
          .find(notificationItem =>
            notificationItem.targetId == notification.targetId &&
            notificationItem.notificationType.code == notification.notificationType.code);
        console.log('selectedNotification >> ', this.notificationArr, selectedNotification);
        if (selectedNotification) {
          selectedNotification.isReaded = true;
        }
        if (this.unReadedNotificationCount) {
          this.unReadedNotificationCount -= 1;
        }
      });
    }
    else {
      this.messagingService.updateNotificationIsRead(notification.targetId, false,notification.id).subscribe(res => {
        console.log(res);
        let selectedNotification = this.notificationArr
          .find(notificationItem =>
            notificationItem.targetId == notification.targetId &&
            notificationItem.notificationType.code == notification.notificationType.code);
        if (selectedNotification) {
          selectedNotification.isReaded = false;
        }
        if (this.unReadedNotificationCount) {
          this.unReadedNotificationCount += 1;
        }
      })
    }
    // Toggle the read status
    // notification.read = !notification.read;

    // Update the notification
    // this._notificationsService.update(notification.id, notification).subscribe();
  }

  /**
   * Delete the given notification
   */
  delete(notification) {
    // Delete the notification
    console.log(this.notificationArr);
   let notificationId = notification.id
    this.messagingService.deleteNotification(notificationId).subscribe(res=> {
      this.notificationArr = this.notificationArr.filter(notification => notification.id !== notificationId)
    });
  }


  onNotificationClicked(notification: any) {
    if (notification && !notification.isReaded) {
      this.messagingService.updateNotificationIsRead(notification.targetId, true,notification.id).subscribe(res => {
        let selectedNotification = this.notificationArr
          .find(notificationItem =>
            notificationItem.targetId == notification.targetId &&
            notificationItem.notificationType.code == notification.notificationType.code);
        console.log('selectedNotification >> ', this.notificationArr, selectedNotification);
        if (selectedNotification) {
          selectedNotification.isReaded = true;
        }
        if (this.unReadedNotificationCount) {
          this.unReadedNotificationCount -= 1;
        }
      });
    }
    // this.toggleNotification();
    this._overlayRef.detach()
    if (notification?.notificationType?.code === this.constant.notificationTypes.SCHEDUL) {
      this._router.navigate(['pages/inventory/order'],
        {
          queryParams: {
            dayId: notification.targetId,
            slotId: notification.targetId2,
            targetType: notification?.notificationType?.code
          }
        });
    }
    if (notification?.notificationType?.code === this.constant.notificationTypes.FOOD_SCHEDUL) {
      this._router.navigate(['pages/food/order'],
        {
          queryParams: {
            dayId: notification.targetId,
            slotId: notification.targetId2,
            targetType: notification?.notificationType?.code
          }
        });
    }
    if (notification?.notificationType?.code === this.constant.notificationTypes.RETURN) {
      this._router.navigate(['pages/inventory/order/' + notification.targetId], {
        queryParams: {targetId2: notification.targetId2, targetType: notification?.notificationType?.code}
      });
    }

    if (notification?.notificationType?.code === this.constant.notificationTypes.FOOD_RETURN) {
      this._router.navigate(['pages/food/order/' + notification.targetId], {
        queryParams: {targetId2: notification.targetId2, targetType: notification?.notificationType?.code}
      });
    }

    if (notification?.notificationType?.code === this.constant.notificationTypes.CUSTOMER_INVOICE) {
      this._router.navigate(['pages/invoices/invoices-list/' + notification.targetId]);

        // this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        //     this._router.navigate(['pages/invoices/invoices-list/' + notification.targetId]);
        // });

    }



    if (notification?.notificationType?.code === this.constant.notificationTypes.TRANSFER) {
      this._router.navigate(['pages/inventory-transfer/view-inventory-transfer'],
        {queryParams: {targetId: notification.targetId, Code: 'SENT'}});
    }
    if (notification?.notificationType?.code === this.constant.notificationTypes.ORDER) {
      this._router.navigate(['pages/inventory/order'],
        {queryParams: {targetId: notification.targetId, orderType: 'EXTERNAL',targetType: notification?.notificationType?.code}});
    }

    if (notification?.notificationType?.code === this.constant.notificationTypes.FOOD_ORDER) {
      this._router.navigate(['pages/food/order'],
        {queryParams: {targetId: notification.targetId, orderType: 'EXTERNAL',targetType: notification?.notificationType?.code}});
    }
  }

  identify(index, item, value) {
    return item.notificationDate;
  }

  cancel(e, id) {
    e.stopPropagation();
    console.log('cancel', id);
    let obj = {id: Number(id), isApproved: false};
    this.messagingService.updateApprovalRequest(obj).subscribe(res => {
      console.log(res);
      if (res.success) {
        this.getUserNotifications();
      }
    });
  }

  accept(e, id) {
    e.stopPropagation();
    console.log('accept', id);
    let obj = {id: Number(id), isApproved: true};
    this.messagingService.updateApprovalRequest(obj).subscribe(res => {
      console.log(res);
      if (res.success) {
        this.getUserNotifications();
      }
    });
  }

  cancelTransfer(e, id) {
    e.stopPropagation();
    console.log('cancel', id);
    let obj = {id: Number(id), isApproved: false};
    this.messagingService.updateTransfer(obj).subscribe(res => {
      console.log(res);
      if (res) {
        this.getUserNotifications();
      }
    });
  }

  acceptTransfer(e, id) {
    e.stopPropagation();
    console.log('accept', id);
    // let obj = {orderAction: {id: Number(id), isApproved: true}};
    let obj = {id: Number(id), isApproved: true};
    this.messagingService.updateTransfer(obj).subscribe(res => {
      console.log(res);
      if (res) {
        this.getUserNotifications();
      }
    });
  }
}
