import { Subscriber } from '../Subscriber';
import { ArgumentOutOfRangeError } from '../util/ArgumentOutOfRangeError';
import { empty } from '../observable/empty';
export function take(count) {
  return source => {
    if (count === 0) {
      return empty();
    } else {
      return source.lift(new TakeOperator(count));
    }
  };
}
class TakeOperator {
  constructor(total) {
    this.total = total;
    if (this.total < 0) {
      throw new ArgumentOutOfRangeError();
    }
  }
  call(subscriber, source) {
    return source.subscribe(new TakeSubscriber(subscriber, this.total));
  }
}
class TakeSubscriber extends Subscriber {
  constructor(destination, total) {
    super(destination);
    this.total = total;
    this.count = 0;
  }
  _next(value) {
    const total = this.total;
    const count = ++this.count;
    if (count <= total) {
      this.destination.next(value);
      if (count === total) {
        this.destination.complete();
        this.unsubscribe();
      }
    }
  }
}
