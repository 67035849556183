import { SimpleOuterSubscriber, innerSubscribe, SimpleInnerSubscriber } from '../innerSubscribe';
export function debounce(durationSelector) {
  return source => source.lift(new DebounceOperator(durationSelector));
}
class DebounceOperator {
  constructor(durationSelector) {
    this.durationSelector = durationSelector;
  }
  call(subscriber, source) {
    return source.subscribe(new DebounceSubscriber(subscriber, this.durationSelector));
  }
}
class DebounceSubscriber extends SimpleOuterSubscriber {
  constructor(destination, durationSelector) {
    super(destination);
    this.durationSelector = durationSelector;
    this.hasValue = false;
  }
  _next(value) {
    try {
      const result = this.durationSelector.call(this, value);
      if (result) {
        this._tryNext(value, result);
      }
    } catch (err) {
      this.destination.error(err);
    }
  }
  _complete() {
    this.emitValue();
    this.destination.complete();
  }
  _tryNext(value, duration) {
    let subscription = this.durationSubscription;
    this.value = value;
    this.hasValue = true;
    if (subscription) {
      subscription.unsubscribe();
      this.remove(subscription);
    }
    subscription = innerSubscribe(duration, new SimpleInnerSubscriber(this));
    if (subscription && !subscription.closed) {
      this.add(this.durationSubscription = subscription);
    }
  }
  notifyNext() {
    this.emitValue();
  }
  notifyComplete() {
    this.emitValue();
  }
  emitValue() {
    if (this.hasValue) {
      const value = this.value;
      const subscription = this.durationSubscription;
      if (subscription) {
        this.durationSubscription = undefined;
        subscription.unsubscribe();
        this.remove(subscription);
      }
      this.value = undefined;
      this.hasValue = false;
      super._next(value);
    }
  }
}
