import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerProgressService {
  constructor(private _baseService: BaseService) {}
  getCustomerProgress(branchId) {
    return this._baseService.get(`api/v1/customer-branch/progress/${branchId}`);
  }

  postDoneFlage(id) {
    return this._baseService.put(id, 'api/v1/customer-done-flage');
  }
}
