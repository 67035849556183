import {inject, Injectable} from '@angular/core';
import {TourService} from 'ngx-ui-tour-md-menu';
import {AuthService} from '../../auth/auth.service';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root'
})
export class GuidelineService {
  public tourService = inject(TourService);

  constructor(
    private _auth: AuthService,
    private _base: BaseService
  ) {
    this.tourService.initialize([{
      anchorId: 'header.first',
      content: 'GUIDE.HEADER_STEP_1_DESC',
      title: 'GUIDE.HEADER_STEP_1'
    }, {
      anchorId: 'header.second',
      content: 'GUIDE.HEADER_STEP_2_DESC',
      title: 'GUIDE.HEADER_STEP_2',
    },{
      anchorId: 'side.first',
      content: 'GUIDE.MANAGE_ACCOUNT_DESC',
      title: 'GUIDE.MANAGE_ACCOUNT'
    }
      ,{
        anchorId: 'side.second',
        content: 'GUIDE.WORK_AREAS_DESC',
        title: 'GUIDE.WORK_AREAS'
      }
    ], {
      enableBackdrop: true,
      nextBtnTitle: 'GUIDE.NEXT',
      prevBtnTitle: 'GUIDE.PREV',
      endBtnTitle: 'GUIDE.END',
      backdropConfig: {
        offset: 10,
      },
    });
  }

  start() {
    this.tourService.start();
    this._base.sideNave.next("open")
  }

  end() {
    this.tourService.end();
    this._auth.disableGuide().subscribe((res) => {
      let currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
      currentUser.showTutorialGuide = false;
      localStorage.setItem('CURRENT_USER', JSON.stringify(currentUser));
      console.log(res);
    });
  }
}
