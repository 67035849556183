import { Subscriber } from '../Subscriber';
export function filter(predicate, thisArg) {
  return function filterOperatorFunction(source) {
    return source.lift(new FilterOperator(predicate, thisArg));
  };
}
class FilterOperator {
  constructor(predicate, thisArg) {
    this.predicate = predicate;
    this.thisArg = thisArg;
  }
  call(subscriber, source) {
    return source.subscribe(new FilterSubscriber(subscriber, this.predicate, this.thisArg));
  }
}
class FilterSubscriber extends Subscriber {
  constructor(destination, predicate, thisArg) {
    super(destination);
    this.predicate = predicate;
    this.thisArg = thisArg;
    this.count = 0;
  }
  _next(value) {
    let result;
    try {
      result = this.predicate.call(this.thisArg, value, this.count++);
    } catch (err) {
      this.destination.error(err);
      return;
    }
    if (result) {
      this.destination.next(value);
    }
  }
}
