import { Observable } from '../Observable';
import { Subscription } from '../Subscription';
export function schedulePromise(input, scheduler) {
  return new Observable(subscriber => {
    const sub = new Subscription();
    sub.add(scheduler.schedule(() => input.then(value => {
      sub.add(scheduler.schedule(() => {
        subscriber.next(value);
        sub.add(scheduler.schedule(() => subscriber.complete()));
      }));
    }, err => {
      sub.add(scheduler.schedule(() => subscriber.error(err)));
    })));
    return sub;
  });
}
