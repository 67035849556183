<mat-form-field appearance="outline">
  <mat-select (selectionChange)="onChange($event)" [formControl]="itemMultiCtrl" [placeholder]="outlineLabel"
              #singleSelect>
    <mat-option>
      <ngx-mat-select-search [formControl]="filterCtrl" [placeholderLabel]="'COMMON.SEARCH' | translate"
                             [noEntriesFoundLabel]="'COMMON.NO_DATA_FOUND' | translate"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let option of options | async" [value]="option[field.optionID]">
      {{option[field.optionCode] ?  option[field.optionName] + ' - ' + option[field.optionCode] : option[field.optionName] }}
    </mat-option>
  </mat-select>
</mat-form-field>
