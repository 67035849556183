import { Subscriber } from './Subscriber';
export class InnerSubscriber extends Subscriber {
  constructor(parent, outerValue, outerIndex) {
    super();
    this.parent = parent;
    this.outerValue = outerValue;
    this.outerIndex = outerIndex;
    this.index = 0;
  }
  _next(value) {
    this.parent.notifyNext(this.outerValue, value, this.outerIndex, this.index++, this);
  }
  _error(error) {
    this.parent.notifyError(error, this);
    this.unsubscribe();
  }
  _complete() {
    this.parent.notifyComplete(this);
    this.unsubscribe();
  }
}
