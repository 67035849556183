<div [formGroup]="filterForm">
  <div class="search-inner" [ngClass]="{ active: active, filterActive: filterActive }">
    <div class="search-item" [ngClass]="{ active: active, filterActive: filterActive }">
      <mat-icon style="cursor: pointer" [class.rtl-mat-icon]="_translate.currentLang === 'ar'" (click)="openSearch()"
                [class.ltr-mat-icon]="_translate.currentLang !== 'ar'">search
      </mat-icon>
      <input (keydown)="checkSpace($event)" #search formControlName="name" [ngClass]="{ active: active }"
             [class.naskh]="_translate.currentLang === 'ar'" [placeholder]="placeholder | translate"/>
      <div class="buttons" *ngIf="active">
        <mat-icon style="cursor: pointer" (click)="closeSearch()" [class.rtl-mat-icon]="_translate.currentLang === 'ar'"
                  [class.ltr-mat-icon]="_translate.currentLang !== 'ar'">highlight_off
        </mat-icon>
        <mat-icon style="cursor: pointer" *ngIf="searchConfig.fields"
                  [class.rtl-mat-icon]="_translate.currentLang === 'ar'"
                  (click)="filterActive = !filterActive" [class.ltr-mat-icon]="_translate.currentLang !== 'ar'">
          {{ filterActive ? "arrow_drop_up" : "arrow_drop_down" }}
        </mat-icon>
      </div>
    </div>
    <div class="filter" *ngIf="filterActive">
      <div *ngIf="searchConfig.fields">
        <div *ngFor="let field of searchConfig.fields">
          <mat-form-field appearance="outline" *ngIf="field.fieldType == 'input'">
            <mat-label>{{ field.placeholder | translate }}</mat-label>
            <input [formControlName]="field.name" (keydown)="checkSpace($event)" [type]="field.type" matInput
                   maxlength="50"/>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="field.fieldType == 'textarea'">
            <mat-label>{{ field.placeholder | translate }}</mat-label>
            <textarea placeholder="BE12348,BE3256,BE3229" matInput [formControlName]="field.name" (keydown)="checkSpace($event)"></textarea>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="field.fieldType == 'date'">
            <mat-label>{{ field.placeholder | translate }}</mat-label>
            <input matInput (keydown)="checkSpace($event)" format=yyyy-MM-dd [matDatepicker]="picker"
                   [formControlName]="field.name">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <!--          <mat-form-field appearance="outline" *ngIf="field.fieldType == 'select'">-->
          <!--            <mat-label>{{ field.placeholder | translate }}</mat-label>-->
          <!--            <mat-select (selectionChange)="getSubcategories($event.value)" [formControlName]="field.name"-->
          <!--              matNativeControl >-->
          <!--              <mat-option *ngFor="let option of field.options" [value]="option[field.optionID]">{{ option[field.optionName] }}-->
          <!--              </mat-option>-->
          <!--            </mat-select>-->
          <!--          </mat-form-field>-->

          <filter-search-autocomplete *ngIf="field.fieldType == 'select'"
                                 [itemMultiCtrl]="filterForm.controls[field.name]"
                                 [outlineLabel]="field.placeholder| translate"
                                 [data]="field.options"
                                 (result)="getCustomerBranch($event)"
                                 [field]="field"
          >
          </filter-search-autocomplete>

          <mat-form-field appearance="outline " *ngIf="field.fieldType == 'sub-select' && field.options.length != 0">
            <mat-label>{{ field.placeholder | translate }}</mat-label>
            <mat-select [formControlName]="field.name" matNativeControl>
              <mat-option *ngFor="let option of field.options"
                          [value]="option[field.optionID]">{{ option[field.optionName] | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
<!--          <mat-form-field appearance="outline" *ngIf="field.fieldType == 'item-select' && field.options.length != 0">-->
<!--            <mat-label>{{ field.placeholder | translate }}</mat-label>-->
<!--            <mat-select [formControlName]="field.name" (selectionChange)="selectData($event.value,field.name)"-->
<!--                        matNativeControl>-->
<!--              <mat-option *ngFor="let option of field.options"-->
<!--                          [value]="option[field.optionID]">{{ option[field.optionName] }}</mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->

          <filter-search-autocomplete *ngIf="field.fieldType == 'item-select' && field.options.length != 0"
                                      [itemMultiCtrl]="filterForm.controls[field.name]"
                                      [outlineLabel]="field.placeholder| translate"
                                      [data]="field.options"
                                      (result)="selectData($event.value,field.name)"
                                      [field]="field"
          >
          </filter-search-autocomplete>

        </div>
        <button (click)="filter()" mat-button color="primary">
          {{ "COMMON.SEARCH" | translate }}
        </button>
        <button (click)="resetFilter()" mat-button color="primary">
          {{ "COMMON.RESET" | translate }}
        </button>
        <button *ngIf="hasPrint" (click)="printPdf()" mat-button color="primary">
          {{ "COMMON.PRINT" | translate }}
        </button>

        <button *ngIf="hasDoc" (click)="printDoc()" mat-button color="primary">
          {{ "COMMON.PRINT_CARD" | translate }}
        </button>

      </div>
    </div>
  </div>
  <div class="tags-search" *ngIf="!filterActive">
    <div *ngFor="let filterItem of searchConfig.fields">
      <div class="item" style="cursor: pointer" (click)="removeFilterItem(filterItem?.name)" *ngIf="
          filterForm.controls[filterItem?.name]?.value &&
          returnName(filterForm.controls[filterItem.name].value,filterItem.fieldType,filterItem.name)
        ">
        <!-- {{ filterItem.placeholder | translate }} : -->
        <span *ngIf="filterItem.fieldType != 'date'">
          {{returnName(filterForm.controls[filterItem.name].value, filterItem.fieldType, filterItem.name)}}
        </span>
        <span *ngIf="filterItem.fieldType == 'date'">
          {{returnName(filterForm.controls[filterItem.name].value, filterItem.fieldType, filterItem.name) | date}}
        </span>
        <mat-icon id="rmvIcon" (click)="removeFilterItem(filterItem.name)">clear</mat-icon>
      </div>
    </div>
  </div>
</div>
