import { Subscriber } from '../Subscriber';
import { Subscription } from '../Subscription';
export function finalize(callback) {
  return source => source.lift(new FinallyOperator(callback));
}
class FinallyOperator {
  constructor(callback) {
    this.callback = callback;
  }
  call(subscriber, source) {
    return source.subscribe(new FinallySubscriber(subscriber, this.callback));
  }
}
class FinallySubscriber extends Subscriber {
  constructor(destination, callback) {
    super(destination);
    this.add(new Subscription(callback));
  }
}
