import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { delay, take, takeUntil } from 'rxjs/operators';
import {MatSelect} from '@angular/material/select';

interface Item {
  id: string;
  name: string;
  branchName: string;
}

@Component({
  selector: 'filter-search-autocomplete',
  templateUrl: './filter-search-autocomplete.component.html',
  styleUrls: ['./filter-search-autocomplete.component.scss'],
})
export class FilterAutoComplete implements OnInit, AfterViewInit, OnDestroy {
  public filterCtrl: UntypedFormControl = new UntypedFormControl();
  public options: ReplaySubject<Item[]> = new ReplaySubject<Item[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  @Input() set data(data: any[]) {
    this._data = data;
    this.options.next(this.data.slice());
  }

  get data(): any[] {
    return this._data;
  }

  private _data: any[];

  @Output() result: EventEmitter<any> = new EventEmitter<any>();
  @Input() outlineLabel: String;
  @Input() itemMultiCtrl: UntypedFormControl;
  @Input() field: any;
  @Input() isSpecialBranch: boolean;

  account: any = {};

  constructor() {}

  ngOnInit() {
    this.filterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy), delay(1000))
      .subscribe(() => {
        this.filterBranches();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onChange($event) {
    console.log($event);
    this.result.emit($event);
  }

  protected setInitialValue() {
    this.options.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      //this.singleSelect.compareWith = (a: Item, b: Item) => a && b && a.id === b.id;
    });
  }

  isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
  protected filterBranches() {
    if (!this.data) {
      return;
    }

    let search = this.filterCtrl.value;
    if (!search) {
      if (this.options) {
        this.options.next(this.data.slice());
      }
      return;
    } else {
      search = search.toLowerCase();
    }

    this.options.next(
      this.data.filter((item) => {
        if (item?.employeeName?.length > 0) {
          return item?.employeeName?.toLowerCase()?.indexOf(search) > -1;
        }
        if (item?.empCode?.length > 0) {
          return item?.empCode?.toLowerCase()?.indexOf(search) > -1;
        }
        if (item?.branchName?.length > 0) {
          return item?.branchName?.toLowerCase()?.indexOf(search) > -1;
        }
        if (this.isNumeric(search)) {
          if (item?.code?.length > 0) {
            return item?.code?.toLowerCase()?.indexOf(String(search)) > -1;
          }
        }
        if (item?.name?.length > 0) {
          return item?.name?.toLowerCase()?.indexOf(search) > -1;
        }
      })
    );
  }
}
