/*
* Copyright 2007 ZXing authors
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*      http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/
// import java.util.List;
/**
 * @author Guenther Grau
 */
var PDF417DetectorResult = /** @class */function () {
  function PDF417DetectorResult(bits, points) {
    this.bits = bits;
    this.points = points;
  }
  PDF417DetectorResult.prototype.getBits = function () {
    return this.bits;
  };
  PDF417DetectorResult.prototype.getPoints = function () {
    return this.points;
  };
  return PDF417DetectorResult;
}();
export default PDF417DetectorResult;