import {Injectable} from '@angular/core';
import {BaseService} from 'src/app/shared/service/base.service';
import {Observable, Subject} from 'rxjs';
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TransferOrderService {
  itemsBaseUrl = 'api/v1/transfer-order';
  private _refreshNeeded$ = new Subject<void>();

  constructor(private _base: BaseService) {
  }
  get refreshNeeded$() {
    return this._refreshNeeded$;
  }
  private editItem: any = null;

  setItem(Order: any) {
    this.editItem = Order;
  }

  getItem(): any {
    return this.editItem;
  }


  getOrderDetailsById(id: number): Observable<any> {
    return this._base
      .get(`${this.itemsBaseUrl}/${id}`)
  }


  getTransferOrderStatus(): Observable<any> {
    return this._base.get('api/v1/transfer-order-status');
  }


  filter(itemObj?: any): Observable<any> {
    return this._base.post(itemObj, `${this.itemsBaseUrl}/filter`);
  }

  deleteOrder(orderId: number) {
    return this._base.deleteById(`${this.itemsBaseUrl}?id=${orderId}`);
  }

  sentByBranch(branchId: number): Observable<any> {
    return this._base
      .get(`${this.itemsBaseUrl}/sent-per-branch/${branchId}`)
  }

  updateTransfer(obj): Observable<any> {
    return this._base.put(obj, `api/v1/transfer-order/change-status`).pipe(
      tap(() => this._refreshNeeded$.next())
    );
  }
}
