import { Subscription } from './Subscription';
export class SubjectSubscription extends Subscription {
  constructor(subject, subscriber) {
    super();
    this.subject = subject;
    this.subscriber = subscriber;
    this.closed = false;
  }
  unsubscribe() {
    if (this.closed) {
      return;
    }
    this.closed = true;
    const subject = this.subject;
    const observers = subject.observers;
    this.subject = null;
    if (!observers || observers.length === 0 || subject.isStopped || subject.closed) {
      return;
    }
    const subscriberIndex = observers.indexOf(this.subscriber);
    if (subscriberIndex !== -1) {
      observers.splice(subscriberIndex, 1);
    }
  }
}
