import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UpdateBranchService {
  // branchConfig = JSON.parse(localStorage.getItem('BRANCH_CONFIG'));
  public subject = new BehaviorSubject<any>([]);

  constructor() {
  }

  public setBranch(newBranch) {
    return this.subject.next(newBranch);
  }
}
