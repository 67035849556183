import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private mealImgSub = new Subject<string>();
  constructor() { }
  watchImg(): Observable<string> {
    return this.mealImgSub.asObservable();
  }

  setImg(key: string): void {
    this.mealImgSub.next(key);
  }
}
