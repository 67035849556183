import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-alert-location',
  templateUrl: './alert-time.component.html',
  styleUrls: ['./alert-time.component.scss']
})
export class AlertTimeComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertTimeComponent>,) { }

  ngOnInit() {
  }
  save(confirm){
    this.dialogRef.close(confirm);
  }
}
