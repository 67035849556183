import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AttachesFilesComponent} from './attaches-files.component';
import {MatMenuModule} from '@angular/material/menu';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {NgxImageCompressService} from 'ngx-image-compress';
import {ImageCropperModule} from 'ngx-image-cropper';

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        // SharedModule,
        ImageCropperModule

    ],
  declarations: [
    AttachesFilesComponent
  ],exports:[
    AttachesFilesComponent
  ],
  providers:[NgxImageCompressService]
})
export class AttachesFilesModule { }
