<ng-container *ngIf="!multiple_upload" class="cameraIcon">
  <div class="avatar-img">
    <img
      *ngIf="avatarImg"
      [src]="avatarImg"
      (click)="showImageDialog(avatarImg)"
    />
    <div class="row screen-lg" style="margin-bottom: 5px; margin-right: 36px">
      <div class="col-md-6 col-sm-6">
        <div (click)="avater.click()">
          <span class="material-icons uploadIcon"> perm_media </span>
          <span class="fileText">{{
            "COMMON.PLEASE_SELECT_FILE" | translate
          }}</span>
          <input
            type="file"
            #avater
            (change)="onSelectAvatarFile($event, avater)"
            [multiple]="multiple_upload"
            hidden
          />
        </div>
      </div>
      <div class="col-md-6 col-sm-6 display-camera-icon">
        <span class="material-icons cameraSpan" (click)="openCameraDialog()"
          >add_a_photo</span
        >
        <span class="fileText" style="margin-top: 3px">{{
          "COMMON.CAMERA" | translate
        }}</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="multiple_upload" class="cameraIcon">
  <div>
    <div class="row" style="margin-bottom: 35px">
      <div style="margin: 0 auto; display: inline-block; height: 115px">
        <div class="row" id="small-row" style="margin-bottom: 5px">
          <div class="col-md-6 col-sm-6">
            <span class="material-icons uploadIcon" (click)="input.click()">
              perm_media
            </span>
            <input
              type="file"
              (change)="onSelectFileChange($event)"
              #input
              accept="image/*"
              [multiple]="multiple_upload"
              hidden
            />
            <span class="fileText" *ngIf="selectFiles.length == 0"
              >{{ "COMMON.PLEASE_SELECT_FILE" | translate }}
            </span>
          </div>
          <div class="col-md-6 col-sm-6 display-camera-icon">
            <span class="material-icons cameraSpan" (click)="openCameraDialog()"
              >add_a_photo</span
            >
            <span
              class="fileText"
              style="margin-top: 3px"
              *ngIf="selectFiles.length == 0"
              >{{ "COMMON.CAMERA" | translate }}</span
            >
          </div>
        </div>

        <span
          class="fileText smallText"
          style="margin-top: 6px; text-align: center"
          *ngIf="selectFiles.length != 0"
          >{{ "COMMON.SELECTED_FILES_NUMBER_IS" | translate }}
          {{ selectFiles.length }}
        </span>
        <button
          class="material-button"
          style="margin-top: 3px"
          id="uploadBtn"
          mat-fab
          color="primary"
          *ngIf="uploadButton"
          [disabled]="disabled && selectFiles.length == 0"
          type="button"
          (click)="uploadFiles()"
        >
          <span class="material-icons"> backup </span>
        </button>
      </div>
    </div>
    <div class="divider"></div>
    <div>
      <div class="itemAttachImg" *ngFor="let attachImage of attachments">
        <img
          [src]="imgUrl + attachImage.imagePath"
          alt=""
          (click)="showImageDialog(imgUrl + attachImage.imagePath)"
        />
        <span class="material-icons" [matMenuTriggerFor]="menu">
          settings_applications
        </span>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deleteItemAttach(attachImage)">
            <mat-icon>delete</mat-icon>
            <span>{{ "COMMON.REMOVE" | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</ng-container>
