import { Subscriber } from '../Subscriber';
export function skipWhile(predicate) {
  return source => source.lift(new SkipWhileOperator(predicate));
}
class SkipWhileOperator {
  constructor(predicate) {
    this.predicate = predicate;
  }
  call(subscriber, source) {
    return source.subscribe(new SkipWhileSubscriber(subscriber, this.predicate));
  }
}
class SkipWhileSubscriber extends Subscriber {
  constructor(destination, predicate) {
    super(destination);
    this.predicate = predicate;
    this.skipping = true;
    this.index = 0;
  }
  _next(value) {
    const destination = this.destination;
    if (this.skipping) {
      this.tryCallPredicate(value);
    }
    if (!this.skipping) {
      destination.next(value);
    }
  }
  tryCallPredicate(value) {
    try {
      const result = this.predicate(value, this.index++);
      this.skipping = Boolean(result);
    } catch (err) {
      this.destination.error(err);
    }
  }
}
