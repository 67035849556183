import { Subscriber } from '../Subscriber';
import { async } from '../scheduler/async';
export function debounceTime(dueTime, scheduler = async) {
  return source => source.lift(new DebounceTimeOperator(dueTime, scheduler));
}
class DebounceTimeOperator {
  constructor(dueTime, scheduler) {
    this.dueTime = dueTime;
    this.scheduler = scheduler;
  }
  call(subscriber, source) {
    return source.subscribe(new DebounceTimeSubscriber(subscriber, this.dueTime, this.scheduler));
  }
}
class DebounceTimeSubscriber extends Subscriber {
  constructor(destination, dueTime, scheduler) {
    super(destination);
    this.dueTime = dueTime;
    this.scheduler = scheduler;
    this.debouncedSubscription = null;
    this.lastValue = null;
    this.hasValue = false;
  }
  _next(value) {
    this.clearDebounce();
    this.lastValue = value;
    this.hasValue = true;
    this.add(this.debouncedSubscription = this.scheduler.schedule(dispatchNext, this.dueTime, this));
  }
  _complete() {
    this.debouncedNext();
    this.destination.complete();
  }
  debouncedNext() {
    this.clearDebounce();
    if (this.hasValue) {
      const {
        lastValue
      } = this;
      this.lastValue = null;
      this.hasValue = false;
      this.destination.next(lastValue);
    }
  }
  clearDebounce() {
    const debouncedSubscription = this.debouncedSubscription;
    if (debouncedSubscription !== null) {
      this.remove(debouncedSubscription);
      debouncedSubscription.unsubscribe();
      this.debouncedSubscription = null;
    }
  }
}
function dispatchNext(subscriber) {
  subscriber.debouncedNext();
}
