<button
  style="margin: 0 4px;display: flex;align-items: center;justify-content: center"
  mat-icon-button
  (click)="openPanel()"
  #notificationsOrigin xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">
  <ng-container *ngIf="unReadedNotificationCount > 0">
        <span class="count_badge">
            <small class="count_badge_count">
                {{unReadedNotificationCount}}
            </small>
        </span>
  </ng-container>
    <mat-icon>notifications_none</mat-icon>
<!--  <img class="material-icons notificationIcon" [src]="bellIconPath" alt="">-->

</button>

<ng-template #notificationsPanel>

  <div
    class="notifications_card ">

    <!-- Header -->
    <div class="notifications_card_header">
      <div class="sm:hidden -ml-1 mr-3" style="margin-right: 0.75rem;margin-left: -0.25rem">
        <button
          mat-icon-button
          (click)="closePanel()">
          <mat-icon
            class="icon-size-5 text-current">close
          </mat-icon>
        </button>
      </div>
      <div class="header_title text-lg font-medium leading-10">{{"NOTIFICATIONS.TITLE" | translate}}</div>
      <div style="margin-left: auto;">
        <button
          mat-icon-button
          [matTooltip]="'Mark all as read'"
          [disabled]="unReadedNotificationCount === 0"
          (click)="markAllAsRead()">
          <mat-icon>drafts
          </mat-icon>
        </button>
      </div>
    </div>

    <!-- Content -->
    <div
      class="notifications_card_content">
      <!-- Notifications -->
      <ng-container *ngFor="let notification of notificationArr; index as i; trackBy: identify">
        <div class="single_notification">
          <div class="notification_icon_info" (click)="onNotificationClicked(notification)">
            <ng-container>
              <div
                class="notification_icon_container">
                <mat-icon
                  class="icon-size-5">{{notification?.notificationType?.icon}}
                </mat-icon>
              </div>
            </ng-container>
            <!-- Title, description & time -->
            <div class="notification_info">
              <ng-container *ngIf="notification?.notificationType?.name">
                <div
                  class="notification_name"
                  [innerHTML]="notification?.notificationType?.name | truncate:[20, '...']"></div>
              </ng-container>
              <ng-container *ngIf="notification.message">
                <div
                  class="notification_message"
                  [innerHTML]="notification.message | truncate:[40, '...']">
                 <span *ngIf="notification.requestValue">{{"NOTIFICATIONS.PERIOD" | translate}}
                   : {{notification.requestValue}} {{"NOTIFICATIONS.MIN" | translate}} </span>
                </div>
              </ng-container>
              <ng-template [ngIf]="notification.notificationType.code
                                    == constant.notificationTypes.SHIFT_EXTENSION_APPROVAL
                                    || notification.notificationType.code
                                    == constant.notificationTypes.SHIFT_EXTENSION_REQUESTER ">
                <ng-template [ngIf]="notification?.isApproved">
                  <span class="span-green-accept">{{"NOTIFICATIONS.ACCEPTED" | translate}}</span>
                </ng-template>
                <ng-template [ngIf]="notification?.isApproved == false">
                  <span class="span-pink-cancel">{{"NOTIFICATIONS.CANCELED" | translate}}</span>
                </ng-template>
              </ng-template>

              <div class="notification_date">
                {{notification.notificationDate}}
              </div>
            </div>
          </div>
          <!--        ***  action  ***  -->
          <div class="notification_actions">
            <!-- Indicator -->
            <div *ngIf="notification?.notificationType?.hasAction &&
                 (notification.notificationType.code == constant.notificationTypes.TRANSFER)"
                 class="notif-actions">
              <ng-template [ngIf]="notification?.isApproved == null">
                <img src="assets/images/icons/cancel.png" [innerHTML]="'ORDER_RETURN.CANCEL' | translate"
                     [alt]="'ORDER_RETURN.CANCEL' | translate"
                     (click)="cancelTransfer($event,notification.targetId)"/>
                <img src="assets/images/icons/accept.png" [innerHTML]="'ORDER_RETURN.ACCEPT' | translate"
                     [alt]="'ORDER_RETURN.ACCEPT' | translate"
                     (click)="acceptTransfer($event,notification.targetId)"/>
<!--                <button class="action-btn-cancel"-->
<!--                        (click)="cancelTransfer($event,notification.targetId)">{{"ORDER_RETURN.CANCEL" | translate}}</button>-->
<!--                <button class="action-btn-accept"-->
<!--                        (click)="acceptTransfer($event,notification.targetId)">{{"ORDER_RETURN.ACCEPT" | translate}}</button>-->
              </ng-template>
            </div>
            <div class="approving_actions"
                 *ngIf="notification?.notificationType?.hasAction &&
                             !(notification.notificationType.code == constant.notificationTypes.TRANSFER)"
            >
              <ng-template [ngIf]="notification?.isApproved == null">
                <img src="assets/images/icons/cancel.png" [innerHTML]="'ORDER_RETURN.CANCEL' | translate"
                     [alt]="'ORDER_RETURN.CANCEL' | translate"
                     (click)="cancel($event,notification.targetId)"/>
                <img src="assets/images/icons/accept.png" [innerHTML]="'ORDER_RETURN.ACCEPT' | translate"
                     [alt]="'ORDER_RETURN.ACCEPT' | translate"
                     (click)="accept($event,notification.targetId)"/>
              </ng-template>
            </div>
            <div class="reading_actions">
              <button
                mat-icon-button
                (click)="toggleRead(notification)"
                [matTooltip]="notification.isReaded ? 'Mark as unread' : 'Mark as read'">
                                      <span
                                        class="read_button"
                                        [ngClass]="{'isRead': notification.isReaded,
                                                      'isNotRead': !notification.isReaded}"></span>
              </button>
              <!-- Remove -->
              <button
                mat-icon-button
                (click)="delete(notification)"
                [matTooltip]="'Remove'">
                <mat-icon
                  style="font-size: 14px">close
                </mat-icon>
              </button>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- No notifications -->
      <ng-container *ngIf="!notificationArr || !notificationArr.length">
        <div class="no_notifications">
          <div class="notification_icon_container">
            <mat-icon>notifications_none</mat-icon>
          </div>
          <div class="no_notification_title">{{'COMMON.NO_NOTIFICATIONS' | translate}}</div>
          <div class="no_notification_desc">{{'COMMON.NO_NOTIFICATIONS_DESC' | translate}}</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
