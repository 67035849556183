<div class="fileContainer" (click)="openFileInput($event)">
  <span class="material-icons uploadIcon"> perm_media </span>
  <input
    type="file"
    (change)="onSelectFileChange($event)"
    accept="image/*"
    [multiple]="multiple_upload"
    [id]="uploadComponentId"
    hidden
  />
  <span class="fileText">{{ "COMMON.PLEASE_SELECT_FILE" | translate }}</span>
</div>
