import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BarcodeFormat} from '@zxing/library';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {json} from 'express';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss']
})
export class QrScannerComponent implements OnInit {
  @Output() getQrCodeValue = new EventEmitter<any>();
  @Input() removeQrCodeValue = new EventEmitter<any>();
  availableDevices: MediaDeviceInfo[];
  deviceCurrent: MediaDeviceInfo;
  deviceSelected: string;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean;
  hasPermission: boolean;

  qrResultString: string;

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = true;

  constructor(private readonly _dialog: MatDialog) {
  }

  clearResult(): void {
    this.qrResultString = null;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    if (this.qrResultString !== resultString) {
      this.qrResultString = resultString;

      let qrCodeValueArray = [];
      resultString.split(",").map((name) =>name).forEach((s,index)=> {
        qrCodeValueArray[index] = s;
      });
      let qrCodeValueObject = {
        employeeId : qrCodeValueArray[0],
        employeeName : qrCodeValueArray[1],
        branchName : qrCodeValueArray[2],
        projectName : qrCodeValueArray[3],
        empCode : qrCodeValueArray[4],
      }
      this.getQrCodeValue.emit(qrCodeValueObject);
      let successAudio = new Audio('./assets/audio/barcode-scanner.mp3');
      successAudio.play();
    }
    return;
  }


  onDeviceChange(device: MediaDeviceInfo) {
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) {
      return;
    }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }


  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }


  ngOnInit(): void {
  }

}
