import { Subscriber } from '../Subscriber';
export function refCount() {
  return function refCountOperatorFunction(source) {
    return source.lift(new RefCountOperator(source));
  };
}
class RefCountOperator {
  constructor(connectable) {
    this.connectable = connectable;
  }
  call(subscriber, source) {
    const {
      connectable
    } = this;
    connectable._refCount++;
    const refCounter = new RefCountSubscriber(subscriber, connectable);
    const subscription = source.subscribe(refCounter);
    if (!refCounter.closed) {
      refCounter.connection = connectable.connect();
    }
    return subscription;
  }
}
class RefCountSubscriber extends Subscriber {
  constructor(destination, connectable) {
    super(destination);
    this.connectable = connectable;
  }
  _unsubscribe() {
    const {
      connectable
    } = this;
    if (!connectable) {
      this.connection = null;
      return;
    }
    this.connectable = null;
    const refCount = connectable._refCount;
    if (refCount <= 0) {
      this.connection = null;
      return;
    }
    connectable._refCount = refCount - 1;
    if (refCount > 1) {
      this.connection = null;
      return;
    }
    const {
      connection
    } = this;
    const sharedConnection = connectable._connection;
    this.connection = null;
    if (sharedConnection && (!connection || sharedConnection === connection)) {
      sharedConnection.unsubscribe();
    }
  }
}
