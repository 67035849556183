import {MatDialog} from '@angular/material/dialog';
import {VideosDialogComponent} from '../../../auth/index/videos-dialog/videos-dialog.component';
import {BaseService} from './../../service/base.service';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {MediaMatcher} from '@angular/cdk/layout';
import {Constant} from '../../constant/constant';
import {
  StartInventoryReceivingService
} from '../../../pages/inventory-receiving/start-inventory-receiving/start-inventory-receiving.service';
import {UpdateBranchService} from '../../service/update-branch.service';
import {ReleaseNotesComponent} from '../release-notes/release-notes.component';
import {AuthService} from '../../../auth/auth.service';
import {UserProfileService} from '../../../pages/user-management/user-profile/user-profile.service';
import {environment} from '../../../../environments/environment';
import {StoreDialogComponent} from '../../../auth/index/index-footer/store-dialog/store-dialog.component';
import {GuidelineService} from '../../service/guideline.service';

export interface SideMenuItem {
  nameAr: string;
  nameEn: string;
  nameFr: string;
  id: number;
  fontIcon: string;
  active?: boolean;
  routingPath?: string;
  sortRanking?: number;
  screens?: SideMenuItem[];
}

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  providers: [Constant]
})
export class SideNavComponent implements OnChanges, OnDestroy {
  private subscriptions = new Subscription();
  mobileQuery: MediaQueryList;
  @Input() folded: boolean;
  sideMenu: SideMenuItem[];
  currentRoute: string;
  currentUser: any;
  userImagePath: String;
  fullName: String;
  activeScreenId: number;
  branches: any[] = [];
  branchConfig: any;
  selectedItem: any;
  versionNumber = '1.1.1';

  constructor(
    private _router: Router,
    public _translate: TranslateService,
    private _base: BaseService,
    private _media: MediaMatcher,
    private dialog: MatDialog,
    private _service: StartInventoryReceivingService,
    private userProfileService: UserProfileService,
    public constant: Constant,
    private updateBranchService: UpdateBranchService,
    public guidelineService: GuidelineService,
    private _auth: AuthService
  ) {

    this.userProfileService.refreshNeeded$.subscribe((res) => {
      this.subscriptions.add(
        this.userProfileService.getcustomerProfile().subscribe(res => {
          console.log(res);
          this.fullName = res.user.fullName;
          this.userImagePath = environment.baseImageUrl + res.user.imagePath;
        })
      );
    });

    if (sessionStorage.getItem('activeScreenId')) {
      this.activeScreenId = Number(sessionStorage.getItem('activeScreenId'));
    }
    this.mobileQuery = this._media.matchMedia('(max-width: 639px)');
    _base.updateUserImage();
    this.userImagePath = this._base.userImagePath;
    this.currentUser = JSON.parse(window.localStorage.getItem('CURRENT_USER'));
    this.fullName = this.currentUser.fullName;
    // if (
    //   !this.constant.userIsCustomer() ||
    //   !this.constant.userIsEmployee()
    // ) {
      this.sideMenu = this.currentUser?.userPrivilage?.menu;
      this.subscriptions.add(
        _router.events
          .pipe(filter((event) => event instanceof NavigationEnd))
          .subscribe((event: any) => {
            this.currentRoute = event.url;
          })
      );
      this.subscriptions.add(
        this._service.getconfigBranches().subscribe(res => {
          if (res.success) {
            let branchConfigLocal = JSON.parse(localStorage.getItem('BRANCH_CONFIG'));
            if (constant.userIsAdmin()) {
              this.branchConfig = [];
              localStorage.setItem('BRANCH_CONFIG', JSON.stringify(this.currentUser.branchConfig));
              if (branchConfigLocal) {
                this.branchConfig = res.data.find(branch => branch.id === branchConfigLocal.id);
              }
            } else {
              if (this.currentUser.branchId) {
                if (branchConfigLocal) {
                  this.branchConfig = res.data.find(branch => branch.id === branchConfigLocal.id);
                } else {
                  this.branchConfig = res.data.find(branch => branch.id === this.currentUser.branchId);
                }
                localStorage.setItem('BRANCH_CONFIG', JSON.stringify(this.branchConfig));
              }
            }
            this.branches = res.data;
          }
        })
      );
    // }
  }


  ngOnChanges() {
    if (this.folded === true) {
      this.sideMenu.forEach((item) => {
        item.active = false;
      });
    }
  }

  toggleChildren(id: number) {
    this.sideMenu.forEach((item) => {
      if (item.id === id) {
        item.active = item.active === true ? false : true;
      } else {
        item.active = false;
      }
    });
  }

  close() {
    this._base.sideNave.next('close');
  }

  changeRoute(route, screenId: number) {
    if (this.mobileQuery.matches) {
      this._base.sideNave.next('close');
    }
    this.activeScreenId = screenId;
    sessionStorage.setItem('activeScreenId', JSON.stringify(this.activeScreenId));
    this._router.navigate([route]);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  openVideosDialog() {
    this.dialog.open(VideosDialogComponent, {
      direction: this._translate.currentLang === 'ar' ? 'rtl' : 'ltr',
      width: '500px',
      height: '300px'
    });
  }

  openReleaseNotesDialog() {
    this.dialog.open(ReleaseNotesComponent, {
      direction: this._translate.currentLang === 'ar' ? 'rtl' : 'ltr',
      width: '600px',
      // height: '300px'
    });
  }

  branchChanged(branch) {
    localStorage.setItem('BRANCH_CONFIG', JSON.stringify(branch));
    this.updateBranchService.setBranch(branch);
  }

  navTo() {
    this.sideMenu.forEach((item) => {
      item.active = false;
    });
    this.activeScreenId = -1;
    sessionStorage.setItem('activeScreenId', JSON.stringify(this.activeScreenId));
    if (this.mobileQuery.matches) {
      this._base.sideNave.next('close');
    }
    if (this.constant.userIsEmployee() || this.constant.userIsAccManager() || this.constant.userIsSupervisor()) {
      this._router.navigateByUrl('/pages/user-management/user-profile');
    }
    if (this.constant.userIsCustomer() || this.constant.userIsAdmin()) {
      this._router.navigateByUrl('/pages/shopping-customer/manage-account');
    }
  }

  openStoreDialog(store) {
    let data;
    if (store === 'appStore') {
      data = {
        'store_name':'App Store',
        'attendance': 'https://apps.apple.com/eg/app/5dma-attendance/id6446276198',
        'provider': 'https://apps.apple.com/eg/app/مزود-خدمة-service-provider/id1601191007',
        'ecommerce': 'https://apps.apple.com/eg/app/5dma-vip-%D8%AE%D8%AF%D9%85%D8%A9-%D9%81%D9%8A-%D8%A7%D9%8A-%D8%A8%D9%8A/id1557248682'
      };
    } else if (store === 'googlePlay') {
      data = {
        'store_name':'Google Play',
        'attendance': 'https://play.google.com/store/apps/details?id=com.DU.Khedma.attendance',
        'provider': 'https://play.google.com/store/apps/details?id=vip.khedma.khedmavip',
        'ecommerce': ''
      };
    } else if (store === 'appGallery') {
      data = {
        'store_name':'App Gallery',
        'attendance': 'https://appgallery.huawei.com/app/C108053273',
        'provider': 'https://appgallery.huawei.com/app/C104814699',
        'ecommerce': 'https://appgallery.huawei.com/app/C104516107'
      };
    }

    this.dialog.open(StoreDialogComponent,
      {
        data: data,
      });
  }


  logout() {
    this._auth.logout();
  }
}
