import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  public _visible: BehaviorSubject<boolean>;

  constructor(private _router: Router) {
    this._visible = new BehaviorSubject(false);

    this._router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.show();
      });

    this._router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd ||
            event instanceof NavigationError ||
            event instanceof NavigationCancel
        )
      )
      .subscribe(() => {
        this.hide();
      });
  }

  show(): void {
    // this._visible.next(true);
  }

  hide(): void {
    // this._visible.next(false);
  }
}
