import {BaseUserProfile} from './../../../shared/modal/user-management/user-profile';
import {BaseService} from './../../../shared/service/base.service';
import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Customer} from 'src/app/shared/modal/shopping-customer/customer';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  customerBaseUrl = 'api/v1/customer';
  private _refreshNeeded$ = new Subject<void>();
  constructor(private _base: BaseService) {}

  get refreshNeeded$() {
    return this._refreshNeeded$;
  }
  /**
   * get all customer
   */
  get(): Observable<Customer[]> {
    return this._base.get(this.customerBaseUrl);
  }

  /**
   * get customer by id
   * @param customerId
   */
  getcustomerProfile(): Observable<Customer> {
    return this._base.get(`api/v1/appuser/user-profile`);
  }

  update(baseUserProfile: BaseUserProfile): Observable<any> {
    return this._base.put(baseUserProfile, 'api/v1/appuser/user-profile').pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    );
  }
  changeUserPassword1(passwordObj: any): Observable<any> {
    return this._base.put(passwordObj, 'api/v1/appuser/change-my-password');
  }

  branchesPeriod(obj: any): Observable<any> {
    return this._base.put(obj, 'api/v1/customer/update/branches/period');
  }
}

// api/v1/customer/update/branches/period
