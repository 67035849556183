import {Observable} from 'rxjs';
import {BaseService} from './../../../shared/service/base.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StartInventoryReceivingService {

  currentUser = JSON.parse(window.localStorage.getItem('CURRENT_USER'));

  constructor(private _base: BaseService) {
  }

  getconfigBranches(): Observable<any> {

    return this._base.get(`api/v1/customer-branch/config`);
  }
  getSuppliersByBranchId(branchId) :Observable<any>{
    return this._base.get(`api/v1/supplier/by-branch/${branchId}`);
  }

}
