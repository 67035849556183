<mat-form-field appearance="outline">
  <mat-select
    (selectionChange)="onChange($event)"
    [formControl]="itemMultiCtrl"
    [placeholder]="outlineLabel"
    #singleSelect
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="branchFilterCtrl"
        [placeholderLabel]="'COMMON.SEARCH' | translate"
        [noEntriesFoundLabel]="'COMMON.NO_DATA_FOUND' | translate"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="isSpecialBranch" [value]="null"></mat-option>
    <mat-option *ngFor="let branch of branches | async" [value]="branch">
      {{ branch.branchName }}
    </mat-option>
  </mat-select>
</mat-form-field>
