<mat-dialog-content class="mat-typography">
<div class="alert-div">
  <mat-icon class="icon-alert">error</mat-icon>
  <span class="alert-word"> {{ "VALIDATIONS.ALERT" | translate }}</span>
  <p class="content alert-text">
    {{ "VALIDATIONS.TIME" | translate }}
  </p>
</div>
</mat-dialog-content>
<mat-dialog-actions style="display: flex;justify-content: center;margin-top: 9px;">
  <button mat-button class="save" (click)="save(true)">
    {{ "COMMON.CLOSE" | translate }}
  </button>
</mat-dialog-actions>

