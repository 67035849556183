import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss'],
})
export class ImageDialogComponent implements OnInit {
  direction: string;
  imagePath = null;
  body = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {
    const lang = localStorage.getItem('LANG');
    this.direction = lang === 'ar' ? 'rtl' : 'ltr';

    if (this.data) {
      if (this.data.imagePath) {
        this.imagePath = this.data.imagePath;
      }
    }
  }


}

