import { SubjectSubscriber } from '../Subject';
import { Observable } from '../Observable';
import { Subscriber } from '../Subscriber';
import { Subscription } from '../Subscription';
import { refCount as higherOrderRefCount } from '../operators/refCount';
export class ConnectableObservable extends Observable {
  constructor(source, subjectFactory) {
    super();
    this.source = source;
    this.subjectFactory = subjectFactory;
    this._refCount = 0;
    this._isComplete = false;
  }
  _subscribe(subscriber) {
    return this.getSubject().subscribe(subscriber);
  }
  getSubject() {
    const subject = this._subject;
    if (!subject || subject.isStopped) {
      this._subject = this.subjectFactory();
    }
    return this._subject;
  }
  connect() {
    let connection = this._connection;
    if (!connection) {
      this._isComplete = false;
      connection = this._connection = new Subscription();
      connection.add(this.source.subscribe(new ConnectableSubscriber(this.getSubject(), this)));
      if (connection.closed) {
        this._connection = null;
        connection = Subscription.EMPTY;
      }
    }
    return connection;
  }
  refCount() {
    return higherOrderRefCount()(this);
  }
}
export const connectableObservableDescriptor = (() => {
  const connectableProto = ConnectableObservable.prototype;
  return {
    operator: {
      value: null
    },
    _refCount: {
      value: 0,
      writable: true
    },
    _subject: {
      value: null,
      writable: true
    },
    _connection: {
      value: null,
      writable: true
    },
    _subscribe: {
      value: connectableProto._subscribe
    },
    _isComplete: {
      value: connectableProto._isComplete,
      writable: true
    },
    getSubject: {
      value: connectableProto.getSubject
    },
    connect: {
      value: connectableProto.connect
    },
    refCount: {
      value: connectableProto.refCount
    }
  };
})();
class ConnectableSubscriber extends SubjectSubscriber {
  constructor(destination, connectable) {
    super(destination);
    this.connectable = connectable;
  }
  _error(err) {
    this._unsubscribe();
    super._error(err);
  }
  _complete() {
    this.connectable._isComplete = true;
    this._unsubscribe();
    super._complete();
  }
  _unsubscribe() {
    const connectable = this.connectable;
    if (connectable) {
      this.connectable = null;
      const connection = connectable._connection;
      connectable._refCount = 0;
      connectable._subject = null;
      connectable._connection = null;
      if (connection) {
        connection.unsubscribe();
      }
    }
  }
}
class RefCountOperator {
  constructor(connectable) {
    this.connectable = connectable;
  }
  call(subscriber, source) {
    const {
      connectable
    } = this;
    connectable._refCount++;
    const refCounter = new RefCountSubscriber(subscriber, connectable);
    const subscription = source.subscribe(refCounter);
    if (!refCounter.closed) {
      refCounter.connection = connectable.connect();
    }
    return subscription;
  }
}
class RefCountSubscriber extends Subscriber {
  constructor(destination, connectable) {
    super(destination);
    this.connectable = connectable;
  }
  _unsubscribe() {
    const {
      connectable
    } = this;
    if (!connectable) {
      this.connection = null;
      return;
    }
    this.connectable = null;
    const refCount = connectable._refCount;
    if (refCount <= 0) {
      this.connection = null;
      return;
    }
    connectable._refCount = refCount - 1;
    if (refCount > 1) {
      this.connection = null;
      return;
    }
    const {
      connection
    } = this;
    const sharedConnection = connectable._connection;
    this.connection = null;
    if (sharedConnection && (!connection || sharedConnection === connection)) {
      sharedConnection.unsubscribe();
    }
  }
}
