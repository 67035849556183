import { Subscriber } from '../Subscriber';
export function distinctUntilChanged(compare, keySelector) {
  return source => source.lift(new DistinctUntilChangedOperator(compare, keySelector));
}
class DistinctUntilChangedOperator {
  constructor(compare, keySelector) {
    this.compare = compare;
    this.keySelector = keySelector;
  }
  call(subscriber, source) {
    return source.subscribe(new DistinctUntilChangedSubscriber(subscriber, this.compare, this.keySelector));
  }
}
class DistinctUntilChangedSubscriber extends Subscriber {
  constructor(destination, compare, keySelector) {
    super(destination);
    this.keySelector = keySelector;
    this.hasKey = false;
    if (typeof compare === 'function') {
      this.compare = compare;
    }
  }
  compare(x, y) {
    return x === y;
  }
  _next(value) {
    let key;
    try {
      const {
        keySelector
      } = this;
      key = keySelector ? keySelector(value) : value;
    } catch (err) {
      return this.destination.error(err);
    }
    let result = false;
    if (this.hasKey) {
      try {
        const {
          compare
        } = this;
        result = compare(this.key, key);
      } catch (err) {
        return this.destination.error(err);
      }
    } else {
      this.hasKey = true;
    }
    if (!result) {
      this.key = key;
      this.destination.next(value);
    }
  }
}
