import { Component, OnInit } from '@angular/core';
import { CustomerProgressService } from '../../../service/customer-progress.service';
import { TranslateService } from '@ngx-translate/core';
import {MatDialogRef} from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { SideMenuItem } from '../../side-nav/side-nav.component';
import { log } from 'console';

@Component({
  selector: 'app-customer-progress-dialog',
  templateUrl: './customer-progress-dialog.component.html',
  styleUrls: ['./customer-progress-dialog.component.scss'],
})
export class CustomerProgressDialogComponent implements OnInit {
  panelOpenState = false;
  fullPercentage: number;
  BRANCHCONFIG: any = JSON.parse(window.localStorage.getItem('BRANCH_CONFIG'));
  currentUser: any = JSON.parse(window.localStorage.getItem('CURRENT_USER'));
  sideMenu: SideMenuItem[] = this.currentUser?.userPrivilage?.menu;
  inventoryAndPurchasing = this.sideMenu.find((m) => m.id === 15);
  finance = this.sideMenu.find((m) => m.id === 19);
  humanResources = this.sideMenu.find((m) => m.id === 20);
  branchId: any = JSON.parse(window.localStorage.getItem('BRANCH_CONFIG')).id;
  inv = {
    name: 'INVENTORY',
    progressStages: [],
    progressPercentage: null,
    stagesLinks: [
      '/pages/shopping-customer/suppliers',
      '/pages/shopping-customer/customer-categories',
      '/pages/shopping-customer/customer-categories',
      '/pages/shopping-customer/customer-items',
      '/pages/shopping-customer/customer-brands',
    ],
  };

  hr = {
    name: 'HR',
    progressStages: [],
    progressPercentage: null,
    stagesLinks: [
      '/pages/hr/customer-employees',
      '/pages/hr/departments',
      '/pages/hr/jobs',
      '/pages/hr/positions',
      '/pages/shopping-customer/calendar-exception',
    ],
  };

  fin = {
    name: 'ACCOUNTING',
    progressStages: [],
    progressPercentage: null,
    stagesLinks: ['/pages/finance/finance-setting'],
  };

  modulesOfProgress = [
    {
      name: 'BRANCHES',
      progressStages: [],
      progressPercentage: null,
      stagesLinks: [
        `/pages/branch/basic`,
        `/pages/branch/details`,
        `/pages/branch/business`,
        `/pages/branch/address`,
        `/pages/branch/delivery-method`,
        `/pages/branch/working-days`,
      ],
    },
  ];

  constructor(
    private _customerProgressService: CustomerProgressService,
    public _translate: TranslateService,
    private ItemDialogRef: MatDialogRef<CustomerProgressDialogComponent>,
    private router: Router
  ) {
    if (this.inventoryAndPurchasing?.id) {
      this.modulesOfProgress.push(this.inv);
    }

    if (this.humanResources?.id) {
      this.modulesOfProgress.push(this.hr);
    }

    if (this.finance?.id) {
      this.modulesOfProgress.push(this.fin);
    }

    this._customerProgressService
      .getCustomerProgress(this.BRANCHCONFIG.id)
      .subscribe((res) => {
        let branchProgressStages = res.data.filter(
          (stage, index) => index <= 6
        );
        branchProgressStages = branchProgressStages.filter(
          (i) => i.code !== 'GENERAL'
        );
        let inventoryProgressStages = res.data.filter(
          (stage, index) => index > 6 && index <= 11
        );
        let hrProgressStages = res.data.filter(
          (stage, index) => index >= 12 && index != 13 && index <= 19
        );
        let accountingProgressStages = res.data.filter(
          (stage, index) => index === 13
        );
        this.modulesOfProgress[0].progressStages = branchProgressStages;
        this.getPercentage(0);

        if (this.inventoryAndPurchasing?.id) {
          let index = this.modulesOfProgress.findIndex(
            (obj) => obj.name === 'INVENTORY'
          );
          this.modulesOfProgress[index].progressStages =
            inventoryProgressStages;
          this.getPercentage(index);
        }
        if (this.humanResources?.id) {
          let index = this.modulesOfProgress.findIndex(
            (obj) => obj.name === 'HR'
          );
          this.modulesOfProgress[index].progressStages = hrProgressStages;
          this.getPercentage(index);
        }
        if (this.finance?.id) {
          let index = this.modulesOfProgress.findIndex(
            (obj) => obj.name === 'ACCOUNTING'
          );
          this.modulesOfProgress[index].progressStages =
            accountingProgressStages;
          this.getPercentage(index);
        }
        this.fullPercentage = Math.round(
          (this.modulesOfProgress
            .map((module) => {
              return module.progressPercentage;
            })
            .reduce((current, accumulate) => current + accumulate, 0) /
            400) *
            100
        );
      });

    console.log(this.modulesOfProgress);
  }

  ngOnInit(): void {}

  getPercentage(index) {
    this.modulesOfProgress[index].progressPercentage = Math.round(
      (this.modulesOfProgress[index].progressStages
        .map((stage) => {
          return stage.flag;
        })
        .filter((flag) => flag === true).length /
        this.modulesOfProgress[index].progressStages.map((stage) => {
          return stage.flag;
        }).length) *
        100
    );
  }

  closeDialog(route, name) {
    if (name === 'BRANCHES') {
      sessionStorage.setItem('branchStepsId', JSON.stringify(this.branchId));
      // const navigationExtras: NavigationExtras = {
      //   queryParams: {
      //     id: this.branchId
      //   },
      //   skipLocationChange: true,
      // };
      this.router.navigate([route]);
    }
    this.ItemDialogRef.close();
  }

  endTask(event: any, stage: any, moduleIndex: number) {
    event.stopPropagation();
    console.log(stage);
    stage.flag = true;
    this.getPercentage(moduleIndex);
  }
}
