import { HttpClient } from '@angular/common/http';
import { Component, OnInit,HostListener } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../service/base.service';
import { ProgressBarService } from '../header/progress-bar/progress-bar.service';
import { tap } from 'rxjs/operators';
import { error } from 'console';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  hidden: boolean;

  constructor(public _translate: TranslateService,private _http: HttpClient, private _progressBarService: ProgressBarService) { }

  ngOnInit(): void {
  }

  hide() {
    this.hidden = true;
  }


  downloadFile(url: string): Observable<Blob> {
    return this._http.get(url, { responseType: 'blob' });
  }

  downloadPrinterApp() {
    const fileUrl = 'https://khdma-production-bucket.s3.eu-west-2.amazonaws.com/setup/5dma-printers-v1.exe';
    this._progressBarService.show();
    this.downloadFile(fileUrl).subscribe(blob => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = '5dma-printers-v1.exe';
      a.click();
      URL.revokeObjectURL(objectUrl);
    }, error=> {console.log(error), {}});
    this._progressBarService.hide();
  }

}
