import { Subscriber } from '../Subscriber';
import { ArgumentOutOfRangeError } from '../util/ArgumentOutOfRangeError';
import { empty } from '../observable/empty';
export function takeLast(count) {
  return function takeLastOperatorFunction(source) {
    if (count === 0) {
      return empty();
    } else {
      return source.lift(new TakeLastOperator(count));
    }
  };
}
class TakeLastOperator {
  constructor(total) {
    this.total = total;
    if (this.total < 0) {
      throw new ArgumentOutOfRangeError();
    }
  }
  call(subscriber, source) {
    return source.subscribe(new TakeLastSubscriber(subscriber, this.total));
  }
}
class TakeLastSubscriber extends Subscriber {
  constructor(destination, total) {
    super(destination);
    this.total = total;
    this.ring = new Array();
    this.count = 0;
  }
  _next(value) {
    const ring = this.ring;
    const total = this.total;
    const count = this.count++;
    if (ring.length < total) {
      ring.push(value);
    } else {
      const index = count % total;
      ring[index] = value;
    }
  }
  _complete() {
    const destination = this.destination;
    let count = this.count;
    if (count > 0) {
      const total = this.count >= this.total ? this.total : this.count;
      const ring = this.ring;
      for (let i = 0; i < total; i++) {
        const idx = count++ % total;
        destination.next(ring[idx]);
      }
    }
    destination.complete();
  }
}
