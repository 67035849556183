var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import { BrowserCodeReader } from './BrowserCodeReader';
import MultiFormatReader from '../core/MultiFormatReader';
var BrowserMultiFormatReader = /** @class */function (_super) {
  __extends(BrowserMultiFormatReader, _super);
  function BrowserMultiFormatReader(hints, timeBetweenScansMillis) {
    if (hints === void 0) {
      hints = null;
    }
    if (timeBetweenScansMillis === void 0) {
      timeBetweenScansMillis = 500;
    }
    var _this = this;
    var reader = new MultiFormatReader();
    reader.setHints(hints);
    _this = _super.call(this, reader, timeBetweenScansMillis) || this;
    return _this;
  }
  /**
   * Overwrite decodeBitmap to call decodeWithState, which will pay
   * attention to the hints set in the constructor function
   */
  BrowserMultiFormatReader.prototype.decodeBitmap = function (binaryBitmap) {
    return this.reader.decodeWithState(binaryBitmap);
  };
  return BrowserMultiFormatReader;
}(BrowserCodeReader);
export { BrowserMultiFormatReader };