import { Subscriber } from '../Subscriber';
export function canReportError(observer) {
  while (observer) {
    const {
      closed,
      destination,
      isStopped
    } = observer;
    if (closed || isStopped) {
      return false;
    } else if (destination && destination instanceof Subscriber) {
      observer = destination;
    } else {
      observer = null;
    }
  }
  return true;
}
