<div class="pdf-dialog-style">
  <mat-dialog-content style="max-height: 100%;">
    <div  mat-dialog-title style="display: flex;align-items: center;justify-content: flex-end;">
      <mat-icon style="cursor: pointer;color: #777979" (click)="downloadPdf()">file_download</mat-icon>
      <mat-icon style="cursor: pointer;color: #777979" (click)="printPdf()">print</mat-icon>
      <mat-icon style="cursor: pointer;color: #ec0a0a" (click)="close()">close</mat-icon>
    </div>
    <div id="pa" *ngIf="pdfUrl" style="display: flex;">
      <object width="1350" height="650px"  [attr.data]="pdfUrl" type="application/pdf"></object>
    </div>
  </mat-dialog-content>
</div>
