/*
 * Copyright 2008 ZXing authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*namespace com.google.zxing.qrcode.encoder {*/
var BlockPair = /** @class */function () {
  function BlockPair(dataBytes, errorCorrectionBytes) {
    this.dataBytes = dataBytes;
    this.errorCorrectionBytes = errorCorrectionBytes;
  }
  BlockPair.prototype.getDataBytes = function () {
    return this.dataBytes;
  };
  BlockPair.prototype.getErrorCorrectionBytes = function () {
    return this.errorCorrectionBytes;
  };
  return BlockPair;
}();
export default BlockPair;