import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseService } from '../../service/base.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class ReleaseNotesComponent implements OnInit {
  modules;

  constructor(
    private _baseService: BaseService,
    public _translate: TranslateService
  ) {
    this._baseService.get('api/v1/module/version').subscribe((res) => {
      console.log(res.data);
      this.modules = res.data;
    });
  }

  ngOnInit(): void {}
}
