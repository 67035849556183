import {ProgressBarService} from './progress-bar.service';
import {Component} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  constructor(public _progressBar: ProgressBarService) {}
}
