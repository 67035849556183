<div
  [class.snack-rtl]="_translate.currentLang === 'ar'"
  [class.snack-ltr]="_translate.currentLang !== 'ar'"
>
  <mat-icon class="snack-icon" [style.color]="_data?.icon_color">
    {{ _data?.icon }}
  </mat-icon>
  <span class="kufi snack-message">{{ _data?.message }}</span>
  <button
    mat-icon-button
    class="action-button"
    (click)="_snackBarRef.dismiss()"
  >
    <mat-icon>{{ _data?.action_icon }}</mat-icon>
  </button>
</div>
