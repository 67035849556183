<mat-form-field appearance="outline">
  <mat-select [formControl]="itemMultiCtrl" (selectionChange)="onChange($event)" [placeholder]="outlineLabel"
              [multiple]="true" #multiSelect>
    <mat-option>
      <ngx-mat-select-search [showToggleAllCheckbox]="true"
                             [toggleAllCheckboxIndeterminate]="isIndeterminate"
                             [toggleAllCheckboxChecked]="isChecked"
                             (toggleAll)="toggleSelectAll($event)"
                             [formControl]="assignFilterCtrl"
                             [placeholderLabel]="'COMMON.SEARCH' | translate"
                             [noEntriesFoundLabel]="'COMMON.NO_DATA_FOUND' | translate">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let assign of filteredAssign | async" [value]="assign">
      {{assign[optionName]}}
    </mat-option>
  </mat-select>
</mat-form-field>

