import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {DomSanitizer} from '@angular/platform-browser';
import {Constant} from 'src/app/shared/constant/constant';

@Component({
  selector: 'app-videos-dialog',
  templateUrl: './videos-dialog.component.html',
  styleUrls: ['./videos-dialog.component.scss'],
})
export class VideosDialogComponent implements OnInit {
  customOptions: OwlOptions;
  safeURL: any;

  constructor(private _sanitizer: DomSanitizer, private constant: Constant) {

    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
      constant.LOGIN_VEDIO
    );
  }

  ngOnInit(): void {}
}
