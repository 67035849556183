<div style="position: relative">
<!--  <img *ngIf="loader == false" onError="this.src='assets/images/user.png'"-->
<!--       class="avatar" style="display: flex;"-->
<!--       [matMenuTriggerFor]="userMenu"-->
<!--       [src]="personImage"-->
<!--  />-->


  <button
    style="margin: 0 2px;display: flex;align-items: center;justify-content: center"
    mat-icon-button
    [matMenuTriggerFor]="userMenu"
    >
    <mat-icon
      class="icon-size-5 text-current">person
    </mat-icon>

  </button>

  <span class="count_badge" *ngIf="loader">
            <span class="count_badge_count material-icons" [ngClass]="{'loader':loader}">
               cached
            </span>
        </span>
</div>

<mat-menu #userMenu="matMenu">
  <button
    *ngFor="let item of userMenuItems"
    mat-menu-item
    [class.rtl]="_translate.currentLang === 'ar'"
    [class.ltr]="_translate.currentLang !== 'ar'"
    (click)="applyAction(item.type, item.target)"
  >
    <mat-icon>{{ item?.icon }}</mat-icon>
    <span>{{ item?.translate | translate }}</span>
  </button>
</mat-menu>
