<button class="close-button" mat-dialog-close  mat-icon-button color="warn">
  <mat-icon>close</mat-icon>
</button>

<div style="padding:0 2rem;max-width: 600px;" [dir]="translate.currentLang === 'ar' ? 'rtl' :'ltr'">
  <mat-dialog-content style="max-height: 100%;">
    <div>
      <h2 style="text-align: center;margin-top: 1rem"> {{sharedData?.translateText | translate}}</h2>
    </div>

  </mat-dialog-content>
</div>
