<div class="dialog-container" [dir]="direction">
<h1 mat-dialog-title
    sytle="text-align: center"
>
{{header ? (header| translate) : 'COMMON.CONFIRM_DELETE_HEADER' | translate}}
</h1>
<div mat-dialog-content style="min-height: 40px"> {{body ? (body| translate) : 'COMMON.CONFIRM_DELETE_BODY' | translate}} </div>
  <div *ngIf="ordersCount" class="flex-print">
    <span *ngIf="countOfOrders" class="span-count">{{ ordersCount +' : ' + countOfOrders }}</span>
  </div>
  <mat-form-field  *ngIf="print" class="example-full-width" appearance="fill">
    <mat-label>{{'COMMON.SAFE_REMAIN_BALANCE' | translate}}</mat-label>
    <input matInput [formControl]="remainBalanceControl" type="number" min="0">
  </mat-form-field>
<div mat-dialog-actions align="end">
  <button *ngIf="!hasInvoice" mat-button class="dialog-cancel" [mat-dialog-close]="false" >{{'COMMON.CANCEL'|translate}}</button>
  <button *ngIf="hasInvoice" mat-button class="dialog-cancel" (click)="hasNoInv()" >
    {{no ? (no| translate) : 'COMMON.CANCEL' | translate}}
  </button>
  <button *ngIf="!print" mat-button  class="dialog-confirm" [mat-dialog-close]="true">
    {{yes ? (yes| translate) : 'COMMON.CONFIRM' | translate}}
   </button>
  <button *ngIf="print" mat-button  class="dialog-confirm" (click)="save(true,false)">
    {{'COMMON.CONFIRM' |translate}}
  </button>

  <button *ngIf="print" mat-button  class="dialog-confirm" (click)="save(true,true)"

  > {{'COMMON.CONFIRM_PRINT' |translate}}
    <!-- <mat-icon>delete</mat-icon> -->
  </button>
</div>
</div>
