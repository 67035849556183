import { Subscriber } from '../Subscriber';
export function takeWhile(predicate, inclusive = false) {
  return source => source.lift(new TakeWhileOperator(predicate, inclusive));
}
class TakeWhileOperator {
  constructor(predicate, inclusive) {
    this.predicate = predicate;
    this.inclusive = inclusive;
  }
  call(subscriber, source) {
    return source.subscribe(new TakeWhileSubscriber(subscriber, this.predicate, this.inclusive));
  }
}
class TakeWhileSubscriber extends Subscriber {
  constructor(destination, predicate, inclusive) {
    super(destination);
    this.predicate = predicate;
    this.inclusive = inclusive;
    this.index = 0;
  }
  _next(value) {
    const destination = this.destination;
    let result;
    try {
      result = this.predicate(value, this.index++);
    } catch (err) {
      destination.error(err);
      return;
    }
    this.nextOrComplete(value, result);
  }
  nextOrComplete(value, predicateResult) {
    const destination = this.destination;
    if (Boolean(predicateResult)) {
      destination.next(value);
    } else {
      if (this.inclusive) {
        destination.next(value);
      }
      destination.complete();
    }
  }
}
