import { Subscriber } from '../Subscriber';
export function map(project, thisArg) {
  return function mapOperation(source) {
    if (typeof project !== 'function') {
      throw new TypeError('argument is not a function. Are you looking for `mapTo()`?');
    }
    return source.lift(new MapOperator(project, thisArg));
  };
}
export class MapOperator {
  constructor(project, thisArg) {
    this.project = project;
    this.thisArg = thisArg;
  }
  call(subscriber, source) {
    return source.subscribe(new MapSubscriber(subscriber, this.project, this.thisArg));
  }
}
class MapSubscriber extends Subscriber {
  constructor(destination, project, thisArg) {
    super(destination);
    this.project = project;
    this.count = 0;
    this.thisArg = thisArg || this;
  }
  _next(value) {
    let result;
    try {
      result = this.project.call(this.thisArg, value, this.count++);
    } catch (err) {
      this.destination.error(err);
      return;
    }
    this.destination.next(result);
  }
}
