import { Subscriber } from './Subscriber';
export class OuterSubscriber extends Subscriber {
  notifyNext(outerValue, innerValue, outerIndex, innerIndex, innerSub) {
    this.destination.next(innerValue);
  }
  notifyError(error, innerSub) {
    this.destination.error(error);
  }
  notifyComplete(innerSub) {
    this.destination.complete();
  }
}
