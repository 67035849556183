import {Component, Input, OnChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'status-tracker',
  templateUrl: './status-tracker.component.html',
  styleUrls: ['./status-tracker.component.scss']
})
export class StatusTrackerComponent implements OnChanges {

  @Input() currentStatus: any;
  @Input() status: any[] = [];

  constructor(public _translate: TranslateService,) {

  }

  ngOnChanges(): void {
    if (this.status && this.status.length > 0){
      this.status = this.status?.filter((st) => st.isConditional != true || st.code == this.currentStatus.code);
    }

  }

}
