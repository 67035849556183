<div>
  <div>
    <p mat-dialog-title style="font-weight: 500">{{"Our Applications On " + data.store_name}}</p>
  </div>
  <div class="row stores_dialog_body">
<!--    <a target="_blank" [href]="data.provider">-->
<!--      <div class="col-sm-12 store_row row">-->
<!--        <div class="col-sm-3">-->
<!--          <img src='./assets/images/store_apps/provider.png' alt="">-->
<!--        </div>-->
<!--        <div class="col-sm-9">-->
<!--          <h3>5dma Provider</h3>-->
<!--        </div>-->
<!--      </div>-->
<!--    </a>-->
    <a target="_blank" [href]="data.attendance">
      <div class="col-sm-12 store_row row">
        <div class="col-sm-3">
          <img src='./assets/images/store_apps/attendance.png' alt="">
        </div>
        <div class="col-sm-9">
          <h3>5dma Attendance</h3>
        </div>
      </div>
    </a>
<!--    <a target="_blank" [href]="data.ecommerce">-->
<!--      <div class="col-sm-12 store_row row">-->
<!--        <div class="col-sm-3">-->
<!--          <img src='./assets/images/store_apps/ecommerce.png' alt="">-->
<!--        </div>-->
<!--        <div class="col-sm-9">-->
<!--          <h3>5dma Ecommerce</h3>-->
<!--        </div>-->
<!--      </div>-->
<!--    </a>-->
  </div>
</div>
