import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-alert-location',
  templateUrl: './alert-location.component.html',
  styleUrls: ['./alert-location.component.scss']
})
export class AlertLocationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertLocationComponent>,) { }

  ngOnInit() {
  }
  save(confirm){
    this.dialogRef.close(confirm);
  }
}
