import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numericUUID'
})
export class NumericUUIDPipe implements PipeTransform {
  transform(value: string): string {
    console.log(value);
    let val = value.toString().substring(0,4);
    return 'O'+val+'x-xy'.replace(/[xy]/g, (c: string): string => {
      const r = Math.random() * 5 | 0;
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(5);
    }).replace(/-/g, '');
  }
}
