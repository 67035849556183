const UnsubscriptionErrorImpl = (() => {
  function UnsubscriptionErrorImpl(errors) {
    Error.call(this);
    this.message = errors ? `${errors.length} errors occurred during unsubscription:
${errors.map((err, i) => `${i + 1}) ${err.toString()}`).join('\n  ')}` : '';
    this.name = 'UnsubscriptionError';
    this.errors = errors;
    return this;
  }
  UnsubscriptionErrorImpl.prototype = Object.create(Error.prototype);
  return UnsubscriptionErrorImpl;
})();
export const UnsubscriptionError = UnsubscriptionErrorImpl;
