<!--<div  id="overlay">-->
<!--  <div class="spinner"></div>-->
<!--  <br/>-->
<!--  &lt;!&ndash; Loading... &ndash;&gt;-->
<!--</div>-->

<div class="wrapper">
  <svg>
    <circle cx="50" cy="50" r="40" stroke="#e44891" stroke-dasharray="78.5 235.5" stroke-width="3" fill="none" />
    <circle cx="50" cy="50" r="30" stroke="#6a1b9a" stroke-dasharray="62.8 188.8" stroke-width="3" fill="none" />
    <circle cx="50" cy="50" r="20" stroke="#450d77" stroke-dasharray="47.1 141.3" stroke-width="3" fill="none" />
  </svg>
</div>

