<div *ngIf="!hidden" [dir]="_translate.currentLang == 'ar' ? 'rtl' : 'ltr'"
  class="alert alert-danger " role="alert">
  <div style="flex-grow: 1;">
    <p class="">{{'PRINTER.ERROR_MSG' | translate}}</p>
  </div>

  <div class="row">
    <div class="col-sm-6" style="padding-right: 0.25rem;padding-left: 0.25rem">
      <button style="width: 100%" color="primary" mat-raised-button (click)="downloadPrinterApp()">{{'COMMON.FIX' | translate}}</button>
    </div>
    <div class="col-sm-6" style="padding-right: 0.25rem;padding-left: 0.25rem">
      <button style="width: 100%" mat-stroked-button (click)="hide()">{{'COMMON.HIDE' | translate}}</button>
    </div>
  </div>
</div>
