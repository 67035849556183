<!--<button-->
<!--  style="margin: 0 2px;display: flex;align-items: center;justify-content: center"-->
<!--  mat-icon-button-->
<!--  (click)="openPanel()"-->
<!--  #checkInOutOrigin xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">-->
<!--  &lt;!&ndash;  <mat-icon>check_none</mat-icon>&ndash;&gt;-->
<!--  <img class="material-icons checkIcon" src="assets/images/icons/employee.png" alt="">-->
<!--</button>-->

<!--<ng-template #checkInOutPanel>-->

<div
  class="check_card" [dir]="_translate.currentLang === 'ar' ? 'rtl' : 'ltr'">

  <!-- Header -->
  <div class="check_card_header row">
    <div class="header_title col-sm-5">{{"ATTENDANCE.TITLE" | translate}}</div>
    <div class="col-sm-6 row">
      <div class="col-sm-3">
        <button
          mat-icon-button
          (click)="serviceNotAvailable()">
          <mat-icon
            class="icon-size-5 text-current">mood
          </mat-icon>
        </button>
      </div>
      <div class="col-sm-3">
        <button
          mat-icon-button
          (click)="serviceNotAvailable()">
          <mat-icon
            class="icon-size-5 text-current">fingerprint
          </mat-icon>
        </button>
      </div>
      <ng-template
        [ngIf]="userTypes.includes('CUST') || (userTypes.includes('SUPERVISOR') && branch?.supervisorCanCheckIn)">
        <div class="super_icon col-sm-3"
        >
          <button
            (click)="checkInOrOutByCode = !checkInOrOutByCode ; checkInOrOutByEmpIdInput = false ; qrCodeValue =null"
            mat-icon-button color="primary">
            <mat-icon
              class="icon-size-5 text-current">qr_code
            </mat-icon>
          </button>
        </div>
        <div class="super_icon col-sm-3 row"
        >
          <button
            mat-icon-button color="primary"
            (click)="checkInOrOutByEmpIdInput = !checkInOrOutByEmpIdInput  ; checkInOrOutByCode =false ; qrCodeValue =null"
          >
            <mat-icon
              class="icon-size-5 text-current">text_format
            </mat-icon>
          </button>
        </div>

      </ng-template>
    </div>
    <div class="col-sm-1">
      <button
        mat-icon-button
        (click)="closePanel()">
        <mat-icon
          class="icon-size-5 text-current">close
        </mat-icon>
      </button>
    </div>

  </div>

  <!-- Content -->
  <div class="check_card_content">
    <div class="shift_content row">
      <div class="col-sm-12 row shift_info">
        <div class="shift_info_icon">
          <mat-icon>schedule</mat-icon>
        </div>
        <div class="col-sm-10" style="display: flex;flex-direction: column; align-items: flex-start;">
          <p class="shift_info_title">{{"ATTENDANCE.CURRENT_TIME" | translate}}</p>
          <p class="shift_info_time" style="direction: ltr">{{date}}</p>
        </div>
      </div>
      <ng-template [ngIf]="shiftDetails">
        <div class="col-sm-12 row shift_info">
          <div class="shift_info_icon">
            <mat-icon>event_available</mat-icon>
          </div>
          <div class="col-sm-6">
            <p class="shift_info_title">{{"ATTENDANCE.SHIFT_TIME" | translate}}</p>
            <p
              class="shift_info_time">{{"COMMON.FROM" | translate}} {{shiftDetails?.startTime}} {{"COMMON.TO" | translate}} {{shiftDetails?.endTime}}</p>
          </div>
          <div class="shift_name col-sm-4" style="margin: 0">
            <p>{{shiftDetails?.shiftName}}</p>
          </div>
        </div>
      </ng-template>
      <ng-template [ngIf]="checkedInStatus">
        <div class="col-sm-12 current_shift_info row">
          <div class="current_shift_info_card">
            <p class="shift_info_title">{{"ATTENDANCE.SHIFT_START" | translate}}</p>
            <p
              class="shift_info_time">{{currentCheckStartTime ? (currentCheckStartTime + ' UTC' | date:'mediumTime') : ''}}</p>
          </div>
          <div class="current_shift_info_card">
            <p class="shift_info_title">{{"ATTENDANCE.DURATION" | translate}}</p>
            <p class="shift_info_time">{{currentCheckDuration}}</p>
          </div>
        </div>
      </ng-template>
      <div *ngIf="!userTypes.includes('CUST')" class="col-sm-12 check_action">
        <ng-template [ngIf]="!checkedInStatus">
          <button style="width: 75%;" mat-stroked-button color="primary"
                  (click)="checkIn()" [disabled]="!shiftDetails">{{"ATTENDANCE.CHECK_IN" | translate}}</button>
        </ng-template>
        <ng-template [ngIf]="checkedInStatus">
          <button style="width: 75%;" mat-stroked-button color="primary"
                  (click)="checkOut()">{{"ATTENDANCE.CHECK_OUT" | translate}}</button>
        </ng-template>
      </div>
    </div>
    <div *ngIf="userTypes.includes('EMP') || userTypes.includes('SUPERVISOR')" class="check_note">
      <p>{{"ATTENDANCE.NOTE" | translate}}</p>
    </div>

    <!--  CHECK EMPLOYEE IN BY ID -->

    <ng-template [ngIf]="checkInOrOutByEmpIdInput && (userTypes.includes('SUPERVISOR') || userTypes.includes('CUST'))">

      <div>
        <form class="col-sm-12 row" style="align-items: center" [formGroup]="employeeCheckForm">
          <mat-form-field class="col-sm-9" appearance="outline">
            <input formControlName="employeeId" matInput
                   placeholder='{{"ATTENDANCE.EMPLOYEE_ID_PLACEHOLDER" | translate}}'>
          </mat-form-field>
          <button style="min-width: 30px;display: flex;align-items: center;justify-content: center"
                  class="col-sm-3" mat-flat-button
                  color="primary" (click)="checkInById()">
            <mat-icon
            >task_alt
            </mat-icon>
          </button>
        </form>
      </div>
    </ng-template>
    <ng-template [ngIf]="checkInOrOutByCode && (userTypes.includes('SUPERVISOR') || userTypes.includes('CUST'))">

      <app-qr-scanner (getQrCodeValue)="addQrCodeValue($event)"></app-qr-scanner>
      <section class="results" *ngIf="qrCodeValue">
        <div class="col-sm-10">
          <div><strong>{{ 'EMPLOYEE-TYPE.ID'|translate }} : </strong><strong>{{ qrCodeValue?.employeeId }}</strong>
          </div>
          <div><strong>{{ 'EMPLOYEE-TYPE.NAME'|translate }} : </strong><strong>{{ qrCodeValue?.employeeName }}</strong>
          </div>
          <div><strong>{{ 'COMMON.BRANCH_NAME'|translate }} : </strong><strong>{{ qrCodeValue?.branchName }}</strong>
          </div>
          <div><strong>{{ 'SIDENAV.PROJECT'|translate }} : </strong><strong>{{ qrCodeValue?.projectName }}</strong>
          </div>
          <div><strong>{{ 'COMMON.CODE'|translate }} : </strong><strong>{{ qrCodeValue?.empCode }}</strong></div>
        </div>
        <div class="col-sm-2" style="text-align: center;
    background: white;
    border-radius: 20px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;">
          <span class="material-icons" [style.color]="checkedIn ?'green' : 'red'">task_alt</span>
        </div>
      </section>
    </ng-template>
  </div>

  <!--  SUPERVISOR ACTIONS -->


</div>

