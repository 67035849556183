import { map } from './map';
import { from } from '../observable/from';
import { SimpleOuterSubscriber, SimpleInnerSubscriber, innerSubscribe } from '../innerSubscribe';
export function mergeMap(project, resultSelector, concurrent = Number.POSITIVE_INFINITY) {
  if (typeof resultSelector === 'function') {
    return source => source.pipe(mergeMap((a, i) => from(project(a, i)).pipe(map((b, ii) => resultSelector(a, b, i, ii))), concurrent));
  } else if (typeof resultSelector === 'number') {
    concurrent = resultSelector;
  }
  return source => source.lift(new MergeMapOperator(project, concurrent));
}
export class MergeMapOperator {
  constructor(project, concurrent = Number.POSITIVE_INFINITY) {
    this.project = project;
    this.concurrent = concurrent;
  }
  call(observer, source) {
    return source.subscribe(new MergeMapSubscriber(observer, this.project, this.concurrent));
  }
}
export class MergeMapSubscriber extends SimpleOuterSubscriber {
  constructor(destination, project, concurrent = Number.POSITIVE_INFINITY) {
    super(destination);
    this.project = project;
    this.concurrent = concurrent;
    this.hasCompleted = false;
    this.buffer = [];
    this.active = 0;
    this.index = 0;
  }
  _next(value) {
    if (this.active < this.concurrent) {
      this._tryNext(value);
    } else {
      this.buffer.push(value);
    }
  }
  _tryNext(value) {
    let result;
    const index = this.index++;
    try {
      result = this.project(value, index);
    } catch (err) {
      this.destination.error(err);
      return;
    }
    this.active++;
    this._innerSub(result);
  }
  _innerSub(ish) {
    const innerSubscriber = new SimpleInnerSubscriber(this);
    const destination = this.destination;
    destination.add(innerSubscriber);
    const innerSubscription = innerSubscribe(ish, innerSubscriber);
    if (innerSubscription !== innerSubscriber) {
      destination.add(innerSubscription);
    }
  }
  _complete() {
    this.hasCompleted = true;
    if (this.active === 0 && this.buffer.length === 0) {
      this.destination.complete();
    }
    this.unsubscribe();
  }
  notifyNext(innerValue) {
    this.destination.next(innerValue);
  }
  notifyComplete() {
    const buffer = this.buffer;
    this.active--;
    if (buffer.length > 0) {
      this._next(buffer.shift());
    } else if (this.active === 0 && this.hasCompleted) {
      this.destination.complete();
    }
  }
}
export const flatMap = mergeMap;
