import { Subscriber } from '../Subscriber';
export function mapTo(value) {
  return source => source.lift(new MapToOperator(value));
}
class MapToOperator {
  constructor(value) {
    this.value = value;
  }
  call(subscriber, source) {
    return source.subscribe(new MapToSubscriber(subscriber, this.value));
  }
}
class MapToSubscriber extends Subscriber {
  constructor(destination, value) {
    super(destination);
    this.value = value;
  }
  _next(x) {
    this.destination.next(this.value);
  }
}
