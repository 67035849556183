import { reduce } from './reduce';
function toArrayReducer(arr, item, index) {
  if (index === 0) {
    return [item];
  }
  arr.push(item);
  return arr;
}
export function toArray() {
  return reduce(toArrayReducer, []);
}
