import { hostReportError } from './hostReportError';
export const subscribeToPromise = promise => subscriber => {
  promise.then(value => {
    if (!subscriber.closed) {
      subscriber.next(value);
      subscriber.complete();
    }
  }, err => subscriber.error(err)).then(null, hostReportError);
  return subscriber;
};
